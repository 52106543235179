import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client'; // Import socket.io-client
import "./scss/ExportImport.scss";
import ZoobbeSelect from '../Global/ZoobbeSelect';
import { config } from '../../config';
import Checkbox from '../Global/Checkbox';
import Spinner from '../Global/Spinner';

import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { fetchUser } from '../../redux/Slices/thunks';
import { hideSnackBar, showSnackBar } from '../../redux/Slices/snackbarSlice';

const ExportImport = () => {
    const [importFile, setImportFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [log, setLog] = useState([]); // Store multiple logs
    const [progress, setProgress] = useState(0); // State for progress message
    const [importSuccess, setImportSuccess] = useState(false); // New state for import success
    const [isImportStart, setImportStart] = useState(false); // New state for import success

    const dispatch = useDispatch();

    const { workspaces } = useSelector(state => state.workspaces);
    const { user } = useSelector(state => state.user);

    useEffect(() => {
        if (!user) {
            dispatch(fetchUser());
        }
    }, [dispatch, user]);

    const workspaceOptions = workspaces?.map(workspace => ({
        value: workspace._id.toString(),
        label: workspace.name,
    }));

    const visibilityOptions = [
        { value: 'workspace', label: 'Workspace' },
        { value: 'public', label: 'Public' },
        { value: 'private', label: 'Private' },
    ];
    const importFromOptions = [
        { value: 'trello', label: 'Trello' },
        { value: 'fluent-boards', label: 'Fluent Boards' },
    ];

    const [selectedWorkspace, setSelectedWorkspace] = useState(workspaceOptions[0]);
    const [selectedVisibility, setSelectedVisibility] = useState(visibilityOptions[0]);
    const [importFromSelect, setImportFromSelect] = useState(importFromOptions[0]);

    // Socket initialization
    useEffect(() => {
        const socket = io(config.API_URI); // Update with your socket server URI
        if (user) {
            const userId = user?.user?._id;
            socket.emit('join', userId);

            // Listen for import progress updates from the server
            socket.on('importProgress', (data) => {
                setImportStart(true);
                dispatch(hideSnackBar());
                setProgress(data.progress);
                setLog(data);
                console.log('New log data:', data);
            });

            console.log('Socket initialized for user:', userId);
        }

        return () => {
            socket.disconnect();
        };
    }, [user]);

    const handleWorkspaceSelect = (workspace) => {
        setSelectedWorkspace(workspace);
    };

    const handleVisibilitySelect = (visibility) => {
        setSelectedVisibility(visibility);
    };

    const handleImportFromSelect = (importFrom) => {
        setImportFromSelect(importFrom);
    };

    const handleExport = async () => {
        setIsLoading(true);
        const token = localStorage.getItem('accessToken');

        try {
            const response = await fetch(config.API_URI + `/api/export`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exported_data.json');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error exporting data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleImport = (e) => {
        setImportFile(e.target.files[0]);
    };

    const uploadImportFile = async () => {
        if (!importFile) return;
        setIsLoading(true);

        dispatch(showSnackBar({
            message: 'Uploading files...',
            type: 'uploading',
        }));


        const formData = new FormData();
        formData.append('file', importFile);
        formData.append('workspaceId', selectedWorkspace.value);
        formData.append('isImportMembers', isChecked);

        const token = localStorage.getItem('accessToken');

        try {
            const response = await fetch(`${config.API_URI}/api/${selectedWorkspace.value}/import`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to import board');
            }

            setImportSuccess(true); // Set import success
            dispatch(fetchWorkspaces());
        } catch (error) {
            console.error('Error importing data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        dispatch(fetchWorkspaces());
    }, [dispatch]);

    return (
        <div className="export-import-container">
            {/* Select Workspace and Import From Options */}
            <div className="select-workspace-to-import">
                <label htmlFor="workspace-type">Workspace</label>
                <ZoobbeSelect
                    options={workspaceOptions}
                    defaultSelectedOption={0}
                    onSelect={handleWorkspaceSelect}
                />

                <label htmlFor="visibility-type">Visibility</label>
                <ZoobbeSelect
                    options={visibilityOptions}
                    defaultSelectedOption={0}
                    onSelect={handleVisibilitySelect}
                />

                <label htmlFor="import-from">Import From</label>
                <ZoobbeSelect
                    options={importFromOptions}
                    defaultSelectedOption={0}
                    onSelect={handleImportFromSelect}
                />

                <div className='create-user-checkbox'>
                    <label>
                        <Checkbox
                            checked={isChecked}
                            onChange={(checked) => setIsChecked(checked)}
                        />
                        <span>Import/Create Board Members</span>
                    </label>
                </div>
            </div>

            {importFile && (
                <div className="uploaded-file-name">
                    Uploaded file: {importFile.name}
                </div>
            )}

            {/* Buttons for Export and Import */}
            <div className="buttons">
                <button className="export-btn" onClick={handleExport} disabled={isLoading}>
                    <span className="material-symbols-outlined">download</span>
                    {isLoading ? 'Exporting...' : 'Export'}
                </button>
                <label htmlFor="import-file" className="import-label">
                    <span className="material-symbols-outlined">upload</span>
                    Upload Import File
                    <input
                        id="import-file"
                        type="file"
                        accept=".json"
                        onChange={handleImport}
                        className="import-input"
                    />
                </label>
                <button
                    className="import-btn"
                    onClick={uploadImportFile}
                    disabled={!importFile || isLoading || !selectedWorkspace?.value}
                >
                    {isLoading ? 'Importing...' : 'Import'}
                </button>
            </div>

            {/* Progress bar and status */}
            {progress && (
                <div className="progress-bar-container">
                    <div className="progress-bar-header">
                        <div className={`import-titles${importSuccess ? ' import-success' : ''}`}>
                            <div className="icon-bar">
                                {importSuccess ? (
                                    <span className="material-symbols-outlined success-icon">
                                        check_circle
                                    </span>
                                ) : (
                                    <>
                                        <span className="material-symbols-outlined import-icon">import_export</span>
                                        <Spinner size={40} color="#3498db" speed={1.5} />
                                    </>
                                )}
                            </div>
                            <span className='title'>
                                {importSuccess ? 'Successfully Imported' : log.title || 'Importing...'}
                            </span>
                        </div>
                        <div className="items-imported">{log.index} / {log.length}</div>
                    </div>
                    <div className="progress-bar">
                        <div
                            className="progress-bar-fill"
                            style={{ width: `${progress}%` }}
                        ></div>
                    </div>
                    <div className="progress-bar-percentage">{progress.toFixed(0)}%</div>
                </div>
            )}
        </div>
    );
};

export default ExportImport;
