import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import Checkbox from '../Global/Checkbox';
import { config } from '../../config';
import './scss/Notifications.scss';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import Spinner from '../Global/Spinner';

const socket = io(config.API_URI);

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(true); // New loading state

    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        const fetchNotifications = async () => {
            setLoading(true); // Start loading
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(`${config.API_URI}/api/notifications`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setNotifications(data);
                } else {
                    console.error('Failed to fetch notifications');
                }
            } catch (error) {
                console.error('Error fetching notifications:', error);
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchNotifications();

        if (user) {
            const userId = user?.user?._id;
            socket.emit('join', userId);

            socket.on('newNotification', (notification) => {
                setNotifications((prevNotifications) => [notification, ...prevNotifications]);
            });

            return () => {
                socket.off('newNotification');
            };
        }
    }, [user]);

    const handleMarkAllAsRead = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            await fetch(`${config.API_URI}/api/notifications/mark-all-read`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setNotifications(notifications.map(notification => ({ ...notification, read: true })));
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    };

    const filteredNotifications = isChecked
        ? notifications.filter(notification => !notification.read)
        : notifications;

    return (
        <div className="notifications-page">
            <div className="notifications-panel">
                <div className="header">
                    <div className="actions">
                        <h2>Notifications</h2>
                        <span className='show-unread-checkbox'>
                            Only show unread
                            <Checkbox
                                checked={isChecked}
                                onChange={(checked) => setIsChecked(checked)}
                            />
                        </span>
                    </div>
                    <button onClick={handleMarkAllAsRead}>Mark all as read</button>
                </div>

                {loading ? (
                    <div className="spin-container">
                        <Spinner />
                    </div>
                ) : (
                    <ul className="notification-list">
                        {filteredNotifications.map((notification) => {
                            if (!notification.initiator) return null;

                            return (
                                <li
                                    key={notification._id}
                                    className={`notification-item ${!notification.read ? 'unread' : ''}`}
                                >
                                    <div className="user-avatar">
                                        {notification.initiator.profilePicture ? (
                                            <img src={notification.initiator.profilePicture} alt={notification.initiator.name} style={{ width: "35px", height: "35px", borderRadius: "50%" }} />
                                        ) : (
                                            <ImagePlaceholder key={notification.initiator._id} size={35} text={notification.initiator.username} />
                                        )}
                                    </div>
                                    <div className="notification-content">
                                        <a className='initiator' href='#'>{notification.initiator.username}</a><br />
                                        <span className='content' dangerouslySetInnerHTML={{ __html: notification.message }}></span>
                                        {notification.targetType && (
                                            <a className={'target-type'} href={`/c/${notification.targetId}`}>{notification.targetType}</a>
                                        )}
                                        <span className="notification-date">{new Date(notification.createdAt).toLocaleString()}</span>
                                    </div>
                                    {!notification.read && <span className="unread-indicator"></span>}
                                    {notification.targetType && (
                                        <a className={'target-link'} href={`/c/${notification.targetId}`}></a>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Notifications;
