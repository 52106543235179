import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ZoobbeSelect from '../Global/ZoobbeSelect';
import './scss/CopyCard.scss';
import { fetchChecklists } from '../../redux/Slices/checklistSlice';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { config } from '../../config';
import { fetchActivities } from '../../redux/Slices/activitiesSlice';
import { fetchBoards } from '../../redux/Slices/boardsSlice';
import { fetchWorkspaces, workspaceSlice } from '../../redux/Slices/workspaceSlice';
import ZoobbeSelectExtend from '../Global/ZoobbeSelectExtend';
import SearchSelect from '../Global/SearchSelect';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';
import Checkbox from '../Global/Checkbox';

const CopyCard = () => {
    const boardId = useSelector(selectBoardId);
    const { boards } = useSelector(state => state.boards);
    const { card } = useSelector(state => state.card);
    const cardId = card.shortId;

    const [title, setTitle] = useState('Checklist');
    const [actionLists, setActionLists] = useState([]);
    const [cards, setCards] = useState([cardId]);
    const [selectedBoard, setSelectedBoard] = useState(boardId || null);
    const [selectedList, setSelectedList] = useState(card.actionList || null);
    const [selectedPosition, setPosition] = useState(card.shortId || null);
    const [cardTitle, setCardTitle] = useState(card.title || '');
    const [isTextareaFocused, setIsTextareaFocused] = useState(false); // New focus state
    const [isChecked, setIsChecked] = useState(false);


    const dispatch = useDispatch();
    const textareaRef = useRef(null);

    useEffect(() => {
        dispatch(fetchBoards());
    }, [dispatch]);

    useEffect(() => {
        if (selectedBoard) {
            const board = boards.find(board => board.shortId === selectedBoard);
            if (board && board.actionLists) {
                setActionLists(board.actionLists);
            } else {
                setActionLists([]);
            }
        }
    }, [selectedBoard, boards]);

    useEffect(() => {
        if (selectedList) {
            const actionList = actionLists.find(actionList => actionList._id === selectedList);
            if (actionList && actionList.cards) {
                setCards(prev => [...actionList.cards, prev]);
            }
        }
    }, [selectedList, actionLists]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = '22px';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [cardTitle]);

    const boardOptions = boards
        ?.filter(board => board.actionLists && board.actionLists.length > 0)
        .map(board => {
            let label = board.title;
            if (board.shortId === boardId) {
                label = `${label}<br><span className="current-option">(current)</span>`;
            }
            return {
                value: board.shortId,
                label: label,
                workspaceShortId: board.workspaceShortId,
                workspaceName: board.workspaceName
            };
        }) || [];

    const actionListOptions = actionLists?.map(actionList => {
        let title = actionList.title;
        if (actionList._id.toString() === card.actionList) {
            title = `${title}<br><span className="current-option">(current)</span>`;
        }
        return {
            value: actionList._id.toString(),
            label: title,
        };
    }) || [];

    const positionOptions = cards?.map((card, index) => {
        let label = (index + 1).toString();
        if (cardId === card.shortId) {
            label = `${label}<br><span className="current-option">(current)</span>`;
        }
        return {
            value: card.shortId,
            label: label,
        };
    }) || [{ value: card.shortId, label: '1' }];

    const handleBoardSelect = (selectedBoardOption) => {
        const board = boards.find(board => board.shortId === selectedBoardOption.value);
        if (board && board.actionLists) {
            setActionLists(board.actionLists);
            setSelectedBoard(selectedBoardOption.value);
            setSelectedList(null);
        }
    };

    const handleActionListSelect = (selectedListOption) => {
        setSelectedList(selectedListOption.value);
        const actionList = actionLists.find(actionList => actionList._id === selectedListOption.value);
        if (actionList.cards.length > 0) {
            setPosition(actionList.cards[actionList.cards.length - 1].shortId);
        } else {
            setPosition(card.shortId);
        }
    };

    const handlePositionSelect = (selectedPosition) => {
        setPosition(selectedPosition.value);
    };

    const handleCopyCard = async () => {
        if (!title) {
            alert('Please enter a title for the checklist');
            return;
        }
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/checklists`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ title })
            });

            if (response.ok) {
                const updatedCard = await response.json();
                dispatch(fetchChecklists(cardId));
                dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
                setTitle('Checklist');
                dispatch(fetchActivities({ cardId }));
            } else {
                console.error('Failed to add checklist');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
            textareaRef.current.select();
        }
    }, []);

    return (
        <div className="copy-card">
            <h2>Copy Card</h2>

            <div className="group">
                <h3>Title</h3>
                <div className={`textarea-wrapper ${isTextareaFocused ? 'focused' : ''}`}>
                    <textarea
                        ref={textareaRef}
                        type="text"
                        placeholder="Enter card title"
                        value={cardTitle}
                        onChange={e => setCardTitle(e.target.value)}
                        rows={1}
                        style={{ overflow: 'hidden' }}
                        spellCheck={false}
                        onFocus={() => setIsTextareaFocused(true)}  // Set focus state
                        onBlur={() => setIsTextareaFocused(false)}   // Remove focus state
                    />
                </div>
            </div>
            <div className="group">
                <h3>Keep members</h3>
                <div className='keep-members'>
                    <Checkbox
                        checked={isChecked}
                        onChange={(checked) => setIsChecked(checked)}
                    />
                    <span className='label' onClick={() => setIsChecked(!isChecked)}>Members({card.users.length})</span>
                </div>
            </div>

            <div className="group">
                <h3>Select Destination</h3>
                <SearchSelect
                    options={boardOptions}
                    onSelect={handleBoardSelect}
                    placeholder="Select an option"
                    isGrouped={true}
                    defaultValue={selectedBoard}
                />
            </div>

            <div className='actionlists-group'>
                <div className="group actionlists">
                    <h3>List</h3>
                    <SearchSelect
                        options={actionListOptions}
                        onSelect={handleActionListSelect}
                        placeholder="Select a list"
                        isGrouped={false}
                        defaultValue={selectedList}
                    />
                </div>
                <div className="group position">
                    <h3>Position</h3>
                    <SearchSelect
                        options={positionOptions}
                        onSelect={handlePositionSelect}
                        placeholder="Select an option"
                        isGrouped={false}
                        defaultValue={selectedPosition}
                    />
                </div>
            </div>

            <button onClick={handleCopyCard}>Create Card</button>
        </div>
    );
};

export default CopyCard;
