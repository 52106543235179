import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isVisible: false,
    contentId: null,
    position: { top: 0, left: 0 },
    targetId: null,
    props: {},
};

const popoverSlice = createSlice({
    name: 'popover',
    initialState,
    reducers: {
        // popoverSlice.js
        togglePopover: (state, action) => {
            const { contentId, position, targetId, props } = action.payload;

            // Check if the popover is open on the same target
            if (state.isVisible && state.contentId === contentId && state.targetId === targetId) {
                state.isVisible = false;
                state.contentId = null;
                state.position = { top: 0, left: 0 };
                state.targetId = null;
                state.props = {};
            } else {
                state.isVisible = true;
                state.contentId = contentId;
                state.position = position;
                state.targetId = targetId;
                state.props = props || {};
            }
        },
        updatePopoverPosition: (state, action) => {
            state.position = action.payload;
        },
    },
});


export const { togglePopover, updatePopoverPosition } = popoverSlice.actions;
export default popoverSlice.reducer;
