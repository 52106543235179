export const config = {
    // API_URI: "https://zoobbe-board.onrender.com",
    // API_URI: "https://api-qcqo.onrender.com",

    API_URI: "https://api-qcqo.onrender.com",
    // API_URI: "http://localhost:5000",
    DOMAIN: "https://zoobbe.com",

    // API_URI: "https://api.zoobbe.com",
};
