import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { config } from '../../config';
import './index.scss';
import { fetchUsers } from '../../redux/Slices/thunks';

const Signup = () => {
  const [signUpData, setSignUpData] = useState({
    name: '',
    email: '',
    password: ''
  });

  const [passwordError, setPasswordError] = useState(''); // New state for password error
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [buttonText, setButtonText] = useState('Continue');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Password validation function
  const isPasswordStrong = (password) => {
    const lengthCheck = password.length >= 8;
    const uppercaseCheck = /[A-Z]/.test(password);
    const lowercaseCheck = /[a-z]/.test(password);
    const numberCheck = /\d/.test(password);
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return lengthCheck && uppercaseCheck && lowercaseCheck && numberCheck && specialCharCheck;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignUpData((prevData) => ({ ...prevData, [name]: value }));
    setMessage(null);
    setError(null);

    // Check password strength on change
    if (name === 'password') {
      if (!isPasswordStrong(value)) {
        setPasswordError('Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character.');
      } else {
        setPasswordError('');
      }
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (passwordError) return; // Prevent submission if password is not strong

    setIsSubmit(true);
    setButtonText('Signing up...');

    try {
      const response = await fetch(config.API_URI + '/api/users/register', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(signUpData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message);
        setMessage(null);
        setIsSubmit(false);
        setButtonText('Continue');
      } else {
        setMessage("Thank you for signing up! We've sent a verification email to your inbox. Please check your email and click the link to verify your account.");
        setError(null);
        dispatch(fetchUsers());

        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError('An error occurred while signing up');
      setMessage(null);
      setIsSubmit(false);
      setButtonText('Continue');
    }
  };

  return (
    <>
      <Helmet>
        <title>Signup - Zoobbe</title>
      </Helmet>

      <div className="login-container">
        <div className="login-box">
          <h1 className="login-logo">Zoobbe</h1>
          <h2 className="login-heading">Sign up to continue</h2>
          <form className="signup-form" onSubmit={handleSignup}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={signUpData.name}
              onChange={handleChange}
              className="login-input"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={signUpData.email}
              onChange={handleChange}
              className="login-input"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={signUpData.password}
              onChange={handleChange}
              className="login-input"
              required
            />
            {/* Display password error message if password is weak */}
            {passwordError && <p className="error-message">{passwordError}</p>}

            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}

            <p className="legal-message">
              By signing up, I accept the Zoobbe, Inc.{' '}
              <Link to="https://www.zoobbe.com/legal/cloud-terms-of-service" target="_blank" rel="noreferrer noopener" className="css-1y8hiba">
                Cloud Terms of Service
              </Link>{' '}
              and acknowledge the{' '}
              <Link to="https://www.zoobbe.com/legal/privacy-policy" target="_blank" rel="noreferrer noopener" className="css-1y8hiba">
                Privacy Policy
              </Link>.
            </p>
            <button type="submit" className="login-button" disabled={isSubmit || passwordError}>
              {buttonText}
            </button>
          </form>
          {/* Additional components */}
        </div>
      </div>
    </>
  );
};

export default Signup;
