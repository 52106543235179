import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './scss/Comments.scss';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import ReactQuill from 'react-quill';
import { sanitizeHtml, timeAgo } from '../../utils/helpers';
import { config } from '../../config';
import Editor2 from '../Editor/Editor';

import {
    mentionedMemberIds,
    resetMentionedIds,
} from "../../redux/Slices/mentionedSlice";
import { fetchCardById } from '../../redux/Slices/cardSlice';
import { addCommentOptimistically, fetchActivities } from '../../redux/Slices/activitiesSlice';
import Editor from '../LexicalEditor/Editor';

const Comments = ({ cardId, isActivityDetails }) => {
    const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);
    // const { activities, activitiesLoading: loading } = useSelector((state) => state.activities);

    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [editingComment, setEditingComment] = useState('');
    const [isAddComment, setAddComment] = useState('');
    const [isCommentBoxClicked, setCommentBoxClicked] = useState(true);
    const [showDetails, setShowDetails] = useState(isActivityDetails); // State to track if details are shown
    const quillRef = useRef(null);

    const dispatch = useDispatch();

    const mentionedIds = useSelector((state) => state.mentioned.mentionedMemberIds);

    useEffect(() => {
        fetchComments();
    }, [cardId]);

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            const length = quill.getLength(); // Get the length of the content

            if (isCommentBoxClicked) {
                quill.setSelection(length - 1, 0); // Set the cursor at the end
                setCommentBoxClicked(false);
            }

            quill.focus(); // Focus the editor
        }
    }, [editingComment, isAddComment, isCommentBoxClicked]);

    const fetchComments = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/comments`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch comments');
            }

            const data = await response.json();
            setComments(data);
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    const handleAddComment = async (e) => {
        const token = localStorage.getItem('accessToken');

        // Create the new comment object for optimistic UI
        const newCommentData = {
            _id: Date.now(), // Use a temporary ID for the optimistic update
            details: newComment,
            initiator: {
                _id: user?.user?._id,
                name: user?.user.username,
                profilePicture: user?.user.profilePicture,
            },
            createdAt: new Date().toISOString(),
            actionType: 'ADDED_COMMENT',
            actionTypeId: null, // Will be populated after the server responds
        };

        // Dispatch the optimistic update
        dispatch(addCommentOptimistically(newCommentData));

        setNewComment('');
        setAddComment(false);


        try {
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/comments`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    comment: newComment,
                    member: user?.user?._id,
                    reacts: [],
                    mentionedIds,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to add comment');
            }

            const addedComment = await response.json();

            // Optionally update the activities with the real data
            // If your API returns the comment ID, you might want to update the actionTypeId here
            // dispatch(fetchActivities({ cardId })); // Uncomment if needed

            setNewComment('');
            setAddComment(false);
            dispatch(resetMentionedIds());
        } catch (error) {
            console.error('Error adding comment:', error);
            // Optionally handle error: revert optimistic update if needed
        }
    };


    const handleActivities = () => {
        setShowDetails((prevState) => !prevState); // Toggle show/hide
        dispatch(fetchActivities({ cardId, isActivityDetails: !showDetails })); // Fetch based on toggle state
    };

    return (
        <div className="comment-section">
            <div className="activity-header">
                <h3>
                    <span className="material-symbols-outlined">
                        activity_zone
                    </span>
                    Activity
                </h3>
                <div className="activity-more-buttons">
                    <button className="hide-checked-item-btn" onClick={handleActivities}>
                        {showDetails ? 'Hide Details' : 'Show Details'}
                    </button>
                </div>
            </div>
            <div className="comment comment-input-field">
                {user?.user.profilePicture ? (
                    <img src={user?.user.profilePicture} alt={user?.user.username} className="comment__profile-img" />
                ) : (
                    <ImagePlaceholder text={user?.user.username} />
                )}

                <div className={`comment__content comment-editor-field`}>
                    <div className="comment__actions">
                        {
                            isAddComment ? (
                                <Editor
                                    value={newComment}
                                    setDescription={setNewComment}
                                    setIsEditingDescription={setAddComment}
                                    handleUpdateCard={handleAddComment}
                                />
                            ) : (
                                <div className="card-comment-content write-comment-field" onClick={() => setAddComment(true)}>
                                    Write a comment...
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comments;
