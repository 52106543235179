import { useDispatch, useSelector } from 'react-redux';
import { togglePopover } from '../redux/Slices/popoverSlice';
import { useRef, useState } from 'react';

const useHandlePopoverClick = () => {
    const dispatch = useDispatch();
    const popoverState = useSelector((state) => state.popover);
    const [popoverTarget, setPopoverTarget] = useState(null); // Store DOM element in React state

    const handlePopoverClick = (e, contentId, props = {}) => {
        const target = e?.currentTarget || e;
        setPopoverTarget(target);

        // Check if the current target matches the open popover
        if (popoverState.isVisible && popoverState.contentId === contentId && popoverState.targetId === target.id) {
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
        } else {
            const rect = target.getBoundingClientRect();
            const position = { top: rect.bottom, left: rect.left };

            dispatch(togglePopover({
                contentId,
                position,
                targetId: target.id,
                props,
            }));
        }
    };



    return { popoverState, handlePopoverClick, popoverTarget };
};

export default useHandlePopoverClick;
