import {
    $applyNodeReplacement,
    TextNode,
} from 'lexical';

// Convert a DOM element to a MentionNode
function $convertMentionElement(domNode) {
    const textContent = domNode.textContent;

    if (textContent) {
        const username = textContent.replace('@', '').trim();
        const node = $createMentionNode(username, username); // Match username with mentionName
        return { node };
    }

    return null;
}

// Style to be applied for MentionNode elements in the DOM
const mentionStyle = 'background-color: rgba(24, 119, 232, 0.2)';

// Define the MentionNode class extending Lexical's TextNode
export class MentionNode extends TextNode {
    constructor(mentionName, username, text = `@${username}`, key) { // Display as @username
        super(text, key);
        this.__mention = mentionName; // Store the mention name
        this.__username = username; // Store the username
    }

    // Return the type of the custom node
    static getType() {
        return 'mention';
    }

    // Clone the MentionNode
    static clone(node) {
        return new MentionNode(node.__mention, node.__username, node.__text, node.__key);
    }

    // Import from JSON for editor state restoration
    static importJSON(serializedNode) {
        const node = $createMentionNode(serializedNode.mentionName, serializedNode.username);
        node.setTextContent(serializedNode.text || `@${serializedNode.username}`);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }

    // Export the node to JSON
    exportJSON() {
        return {
            ...super.exportJSON(),
            mentionName: this.__mention,
            username: this.__username, // Include username in export
            type: 'mention',
            version: 1,
        };
    }

    // Create the DOM element for the MentionNode
    createDOM(config) {
        const dom = super.createDOM(config);
        dom.className = `mention ${this.__username}`; // Add mention class and username as a class
        return dom;
    }

    // Export the node as a DOM element
    exportDOM() {
        const element = document.createElement('span');
        element.setAttribute('data-lexical-mention', 'true');
        element.textContent = `@${this.__username}`; // Display as @username
        element.className = `mention ${this.__username}`; // Add mention class for consistent styling
        return { element };
    }

    // Define how the node should be imported from a DOM element
    static importDOM() {
        return {
            span: (domNode) => {
                if (!domNode.hasAttribute('data-lexical-mention')) {
                    return null;
                }
                return {
                    conversion: $convertMentionElement,
                    priority: 1,
                };
            },
        };
    }

    // The node represents a text entity
    isTextEntity() {
        return true;
    }

    // Prevent inserting text before the mention node
    canInsertTextBefore() {
        return false;
    }

    // Prevent inserting text after the mention node
    canInsertTextAfter() {
        return false;
    }
}

// Function to create a new MentionNode
export function $createMentionNode(mentionName, username) {
    const mentionNode = new MentionNode(mentionName, username);
    mentionNode.setMode('segmented').toggleDirectionless(); // Custom behavior for mention nodes
    return $applyNodeReplacement(mentionNode); // Apply node replacement if necessary
}

// Check if a node is an instance of MentionNode
export function $isMentionNode(node) {
    return node instanceof MentionNode;
}
