import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { togglePopover } from '../redux/Slices/popoverSlice';

const useOutsideClick = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        // Prevent closing if the click is on a popover trigger
        if (event.target.closest('[data-popover-trigger]')) return;

        onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, onClickOutside]);
};


export default useOutsideClick;
