import React from "react";
import { Link } from "react-router-dom";
import { find, getTextColor, toSlug } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/Slices/modalSlice";

import './scss/BaordSection.scss'
import MemberImage from "../Global/MemberImage";

const RecentView = () => {

    const dispatch = useDispatch();

    const { workspaces } = useSelector(state => state.workspaces);
    const recentlyViewed = find.get(workspaces, { name: 'recentViewedBoards', limit: 3 });


    const addTransparency = (rgbColor, alpha = 1) => {
        // Ensure the RGB color is in the correct format (e.g., "rgb(255, 0, 0)")
        const match = rgbColor?.match(/\d+/g);
        if (!match || match.length < 3) {
            return rgbColor; // Return original if format is invalid
        }

        const [r, g, b] = match;
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return (
        <div className="boards-page-board-section-list">
            {recentlyViewed?.map(board => {
                // const members = find.get(workspaces, { name: 'members', board: board._id });
                const cards = find.get(workspaces, { name: 'cards', board: board._id });
                const members = (board.members).filter(member => member._id);

                const coverColor =
                    Array.isArray(board?.cover?.coverColor) && board.cover.coverColor.length > 2
                        ? board.cover.coverColor[2]
                        : 'transparent';

                const opacity = '0.6';


                return (
                    <div
                        className="boards-page-board-section-list-item"
                        key={board._id}
                        style={{
                            backgroundImage: `url(${board?.cover?.url})`,
                            backgroundSize: 'cover', // Ensures the image covers the full container
                            backgroundPosition: 'center center', // Centers the image
                            borderColor: `${coverColor ? addTransparency(coverColor, opacity) : 'transparent'}`

                        }}
                    >
                        {
                            coverColor && (
                                <div
                                    className="board-gradient"
                                    style={{
                                        background: `${coverColor ? addTransparency(coverColor, opacity) : 'transparent'}`
                                    }}
                                ></div>
                            )
                        }

                        <div className="board-item">
                            <div className="board-title" style={{ color: `${getTextColor(coverColor)}` }}> {board.title}</div>

                            <div className="board-details">
                                <div className="board-members">
                                    <MemberImage members={members} type={'board'} size={30} />
                                    {
                                        members.length > 3 && (
                                            <span className="member-count">+{members.length - 3}</span>
                                        )
                                    }
                                </div>
                                <div className="board-stats" style={{ color: `${getTextColor(coverColor)}` }}>
                                    <span className="material-symbols-outlined" style={{ color: `${getTextColor(coverColor)}` }}>
                                        credit_card
                                    </span>
                                    <span>{cards.length}</span>
                                </div>
                            </div>
                        </div>
                        <Link className="board-tile-link" to={board.permalink}></Link>
                    </div>
                )
            })}
        </div>
    )
}

export default RecentView;