import React from 'react';
import PropTypes from 'prop-types';
import './ImagePlaceholder.scss';

const ImagePlaceholder = ({
    text,
    bgColor = "#454f5999",
    size = 30,
    textColor = "var(--single-card-text-color)",
    radius = "50%",
    fontSize = "15px",
    className = ""
}) => {
    const style = {
        backgroundColor: bgColor,
        width: size,
        height: size,
        color: textColor,
        borderRadius: radius,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: fontSize,
        textTransform: 'uppercase',
    };

    return (
        <div className={`image-placeholder${className}`} style={style}>
            {text ? text.charAt(0) : ''}
        </div>
    );
};

ImagePlaceholder.propTypes = {
    text: PropTypes.string,
    bgColor: PropTypes.string,
    size: PropTypes.number,
    textColor: PropTypes.string,
    radius: PropTypes.string,
};


export default ImagePlaceholder;
