import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { config } from '../../config';

const VerificationRequired = () => {
    const { user } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    console.log(config.API_URI);

    const handleResendVerification = async (e, email) => {
        e.preventDefault();
        if (!email) {
            setError("Please enter a valid email to resend verification.");
            return;
        }

        setLoading(true);
        setMessage(null);
        setError(null);

        try {
            const response = await fetch(config.API_URI + '/api/users/resend-verification', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.message || 'Failed to resend verification email.');
            } else {
                setMessage('Verification email resent. Please check your inbox.');
            }
        } catch (error) {
            console.error('Error resending verification email:', error.message);
            setError('An error occurred while resending verification email.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px', color: 'var(--primary-text-color)' }}>
            <h2>Your account is not verified</h2>
            <p>Please check your email and verify your account to access all features.</p>

            {loading && <p>Sending verification email...</p>}

            {message && <p style={{ color: 'green' }}>{message}</p>}

            {error && <p style={{ color: 'red' }}>{error}</p>}

            <p>
                Didn’t receive an email? <span
                    style={{ cursor: 'pointer', color: 'var(--brand-color)', textDecoration: 'underline' }}
                    onClick={(e) => handleResendVerification(e, user?.user?.email)}
                > Resend</span> verification email.
            </p>
        </div>
    );
};

export default VerificationRequired;
