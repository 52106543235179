import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const VerificationSuccess = () => {
    const { user } = useSelector((state) => state.user); // Get user from Redux store
    const navigate = useNavigate();

    const handleRedirect = () => {
        if (user && user.user.verified) {
            // If user is logged in and verified, navigate to their boards
            navigate(`/u/${user.username}/boards`);
        } else {
            // If user is not logged in, navigate to the login page
            navigate('/login');
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px', color: 'var(--primary-text-color)' }}>
            <h2>Verification Successful!</h2>
            <p>Your email has been successfully verified.</p>
            <button onClick={handleRedirect} style={{ padding: '10px 20px', cursor: 'pointer' }}>
                Go to {user && user.user.verified ? 'Boards' : 'Login'}
            </button>
        </div>
    );
};

export default VerificationSuccess;
