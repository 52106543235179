import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Spinner from '../Global/Spinner';
import './SearchResults.scss';
import { find } from "../../utils/helpers";
import { config } from '../../config';

const SearchResults = ({ query, setQuery, setSearchResult }) => {
    const [results, setResults] = useState({ boards: [], cards: [] }); // Search results
    const [loading, setLoading] = useState(false); // Loading state
    const [debouncedQuery, setDebouncedQuery] = useState(query); // Debounced query
    const [showResults, setShowResults] = useState(true); // Manage visibility of results

    const { workspaces } = useSelector(state => state.workspaces);
    const recentlyViewed = find.get(workspaces, { name: 'recentViewedBoards' });

    const navigate = useNavigate(); // Initialize the navigate function

    // Debounce the query input
    useEffect(() => {
        setLoading(false);
        if (query) {
            setLoading(true); // Show loading when typing starts
        }

        const timer = setTimeout(() => {
            setDebouncedQuery(query); // Set the debounced query after typing stops
        }, 200); // Delay of 200ms after the user stops typing

        return () => clearTimeout(timer); // Clean up the timer
    }, [query]);

    // Fetch search results
    const fetchResults = async () => {
        if (!debouncedQuery) return;

        try {
            const token = localStorage.getItem('accessToken');

            let response = await fetch(`${config.API_URI}/api/global/search?query=${debouncedQuery}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            response = await response.json();

            if (response.success) {
                setResults(response.data);
                setLoading(false); // Hide loading after receiving results
            }

        } catch (error) {
            console.log(error);
            setLoading(false); // Hide loading on error
        }
    };

    useEffect(() => {
        if (debouncedQuery) {
            fetchResults(); // Fetch results only when debouncedQuery changes
        }
    }, [debouncedQuery]);

    const handleItemClick = (permalink) => {
        setShowResults(false); // Hide results on click
        setSearchResult(false);
        setQuery('');
        // navigate(permalink); // Navigate to the target route

        window.location.href = permalink;
    };

    return (

        <>
            {
                showResults && (
                    <div className="search-results">
                        {loading && (
                            <div className='spinner-container'>
                                <Spinner size={30} speed={1.5} strokeWidth={5} />
                            </div>
                        )}

                        {!debouncedQuery && !loading && recentlyViewed.length > 0 && (
                            <section className="category">
                                <h4>Recent</h4>
                                {!loading && recentlyViewed?.map(board => {
                                    console.log(board)
                                    return (
                                        <div
                                            className="result-item"
                                            key={board._id}
                                            onClick={() => handleItemClick(board.permalink)}
                                        >

                                            {
                                                board.cover?.sizes?.thumbnail ? (
                                                    <div
                                                        className="icon board-icon"
                                                        style={{ backgroundImage: `url(${board.cover?.sizes?.medium})` }}
                                                    ></div>
                                                ) : (
                                                    <div className="icon card-icon"></div>
                                                )
                                            }
                                            <div className="content">
                                                <p className="title">{board.title}</p>
                                                <p className="subtitle">{board.workspace.name}</p>
                                            </div>

                                            <Link className="card-permalink" to={board.permalink}></Link>

                                        </div>
                                    )
                                })}
                            </section>
                        )}

                        {/* Results */}
                        {debouncedQuery && !loading && (
                            <>
                                {results.cards.length > 0 && (
                                    <section className="category">
                                        <h4>CARDS</h4>
                                        {results.cards.map((card) => (
                                            <div
                                                className="result-item"
                                                key={card._id}
                                                onClick={() => handleItemClick(card.permalink)}
                                            >
                                                <div className="icon card-icon">
                                                    <span className="material-symbols-outlined">video_label</span>
                                                </div>
                                                <div className="content">
                                                    <p className="title">{card.title}</p>
                                                    <p className="subtitle">{card.boardTitle}: {card.actionList.title}</p>
                                                </div>
                                                <Link className="card-permalink" to={card.permalink}></Link>

                                            </div>
                                        ))}
                                    </section>
                                )}

                                {results.boards.length > 0 && (
                                    <section className="category">
                                        <h4>BOARDS</h4>
                                        {results.boards.map((board) => {
                                            console.log(board)
                                            return (
                                                <div
                                                    className="result-item"
                                                    key={board._id}
                                                    onClick={() => handleItemClick(board.permalink)}
                                                >
                                                    {
                                                        board.cover?.sizes?.thumbnail && (
                                                            <div
                                                                className="icon board-icon"
                                                                style={{ backgroundImage: `url(${board.cover?.sizes?.thumbnail})` }}
                                                            ></div>
                                                        )
                                                    }
                                                    <div className="content">
                                                        <p className="title">{board.title}</p>
                                                        <p className="subtitle">{board.workspace.name}</p>
                                                    </div>

                                                    <Link className="board-permalink" to={board.permalink}></Link>

                                                </div>
                                            )
                                        })}
                                    </section>
                                )}

                                {results.boards.length < 1 && results.cards.length < 1 && (
                                    <section className="category noting-found">
                                        <span class="material-icons">
                                            search_off
                                        </span>
                                        <div className="notging-found-content">
                                            We couldn't find anything matching your search.
                                            Try again with a different term, or refine your results with Advanced Search
                                        </div>
                                    </section>
                                )}




                            </>
                        )}

                    </div>
                )
            }
        </>

    );
};

export default SearchResults;
