import React, { useState, useEffect, useRef, act } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ZoobbeSelect from '../Global/ZoobbeSelect';
import Spinner from '../Global/Spinner';

import './scss/MoveCard.scss';
import { fetchChecklists } from '../../redux/Slices/checklistSlice';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { config } from '../../config';
import { fetchActivities } from '../../redux/Slices/activitiesSlice';
import { fetchBoards } from '../../redux/Slices/boardsSlice';
import { fetchWorkspaces, workspaceSlice } from '../../redux/Slices/workspaceSlice';
import ZoobbeSelectExtend from '../Global/ZoobbeSelectExtend';
import SearchSelect from '../Global/SearchSelect';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';
import { fetchBoardById } from '../../redux/Slices/boardSlice';


const MoveCard = () => {
    const boardId = useSelector(selectBoardId);
    const { boards, status } = useSelector(state => state.boards);
    const { card } = useSelector(state => state.card);
    const cardId = card.shortId;


    const [title, setTitle] = useState('Checklist');
    const [actionLists, setActionLists] = useState([]);
    const [cards, setCards] = useState([cardId]);
    const [selectedBoard, setSelectedBoard] = useState(boardId || null);
    const [selectedList, setSelectedList] = useState(card.actionList || null);
    const [selectedPosition, setPosition] = useState(card.shortId || null);
    const [targetPosition, setTargetPostion] = useState(null);
    const [loading, setLoading] = useState(false);


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchBoards());
    }, [dispatch]);

    useEffect(() => {
        if (selectedBoard) {
            const board = boards.find(board => board.shortId === selectedBoard);

            if (board && board.actionLists) {
                setActionLists(board.actionLists);
            } else {
                setActionLists([]);
            }
        }
    }, [selectedBoard, boards]);

    useEffect(() => {
        if (selectedList) {
            const actionList = actionLists.find(actionList => actionList._id === selectedList);
            if (actionList && actionList.cards) {
                setCards((prev) => [...actionList.cards, prev]);
            }
        }
    }, [selectedList, actionLists]);

    // Filter boards to exclude those with empty actionLists
    const boardOptions = boards
        ?.filter(board => board.actionLists && board.actionLists.length > 0)
        .map(board => {
            let label = board.title;
            if (board.shortId === boardId) {
                label = `${label}<br><span className="current-option">(current)</span>`;
            }
            return ({
                value: board.shortId,
                label: label,
                workspaceShortId: board.workspaceShortId,
                workspaceName: board.workspaceName
            })
        }) || [];

    const actionListOptions = actionLists?.map(actionList => {
        let title = actionList.title;
        if (actionList._id.toString() === card.actionList) {
            title = `${title}<br><span className="current-option">(current)</span>`; // Adding newline before (current)
        }
        return ({
            value: actionList._id.toString(),
            label: title,
        });
    }) || [];


    const positionOptions = actionLists.find(actionList => actionList._id === selectedList)?.cards.length
        ? actionLists.find(actionList => actionList._id === selectedList).cards.map((card, index) => {
            let label = (index + 1).toString();

            if (cardId === card.shortId) {
                label = `${label}<br><span className="current-option">(current)</span>`;
            }

            return {
                value: card.shortId,
                label: label,
                index: index,
            };
        })
        : [{ value: card.shortId, label: '1' }];




    // Handle board selection and update action lists accordingly
    const handleBoardSelect = (selectedBoardOption) => {
        const board = boards.find(board => board.shortId === selectedBoardOption.value);

        if (board && board.actionLists) {
            setActionLists(board.actionLists); // Update action lists for the selected board
            setSelectedBoard(selectedBoardOption.value); // Update selected board
            setSelectedList(null); // Reset the selected action list
        }

    };

    // Handle action list selection
    const handleActionListSelect = (selectedListOption) => {

        setSelectedList(selectedListOption.value);

        const actionList = actionLists.find(actionList => actionList._id === selectedListOption.value);

        if (actionList.cards.length > 0) {
            setPosition(actionList.cards[actionList.cards.length - 1].shortId);
        }
        else {
            setPosition(card.shortId);
        }

    };


    // Handle action list selection
    const handlePositionSelect = (selectedPosition) => {
        setPosition(selectedPosition.value);
        setTargetPostion(selectedPosition.index);
    };


    const handleMoveCard = async (cardId, targetListId, targetPosition) => {
        setLoading(true); // Set loading to true when the move operation starts

        const token = localStorage.getItem('accessToken');
        try {
            await fetch(`${config.API_URI}/api/boards/${boardId}/cards/order`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ cardId, targetListId, targetPosition }),
            });
        } catch (error) {
            console.error('Error moving card:', error);
        } finally {
            setLoading(false); // Set loading to false when the operation ends
        }
    };


    return (
        <div className="move-card">
            <h2>Move Card</h2>

            <div className="group">
                <h3>Select Destination</h3>
                <SearchSelect
                    options={boardOptions}
                    onSelect={handleBoardSelect}
                    placeholder="Select an option"
                    isGrouped={true}
                    defaultValue={selectedBoard}
                />
            </div>
            <div className='actionlists-group'>
                <div className="group actionlists">
                    <h3>List</h3>
                    <SearchSelect
                        options={actionListOptions}
                        onSelect={handleActionListSelect}
                        placeholder="Select a list"
                        isGrouped={false}
                        defaultValue={selectedList}
                    />
                </div>
                <div className="group position">
                    <h3>Possition</h3>
                    <SearchSelect
                        options={positionOptions}
                        onSelect={handlePositionSelect}
                        placeholder="Select an option"
                        isGrouped={false}
                        defaultValue={selectedPosition}

                    />
                </div>
            </div>
            <button onClick={() => handleMoveCard(card._id, selectedList, targetPosition)} disabled={loading}>
                {loading ? (
                    <Spinner size={20} color="#ffffff" speed={1.5} strokeWidth={5} />

                ) : 'Move'}
            </button>
        </div>
    );
};

export default MoveCard;
