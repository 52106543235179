import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideSnackBar } from '../../redux/Slices/snackbarSlice';
import './scss/SnackBar.scss';

const SnackBar = () => {
  const dispatch = useDispatch();
  const { isOpen, message, type } = useSelector((state) => state.snackbar);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsExiting(false); // Reset exit state when Snackbar opens
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsExiting(true); // Trigger exit animation
    setTimeout(() => {
      dispatch(hideSnackBar()); // Hide snackbar from Redux state after animation
    }, 300); // Match this duration to your CSS exit animation
  };

  if (!isOpen && !isExiting) return null; // Don't render if closed and no exit animation

  return (
    <div className={`snackbar snackbar-${type} ${isExiting ? 'snackbar-exit' : ''}`}>
      {message}
      <button className="snackbar-close-btn" onClick={handleClose}>
        &times;
      </button>
    </div>
  );
};

export default SnackBar;
