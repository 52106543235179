import React, { useState, useRef, useEffect } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';
import './scss/SearchSelect.scss';

const SearchSelect = ({ options, isMultiple = false, onSelect, placeholder = 'Select an option', isGrouped = false, defaultValue }) => {
    // Ensure defaultValue is always an array
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOptions, setSelectedOptions] = useState(defaultValue || null); // Safeguard against null or undefined
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const selectRef = useRef();
    const dropdownRef = useRef();
    const selectedOptionRef = useRef(null);

    useOutsideClick(selectRef, () => {
        setIsOpen(false);
        setIsFocused(false);
    });

    useEffect(() => {
        setFilteredOptions(
            options && options.filter(option =>
                option.label.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, options]);

    useEffect(() => {
        // Safeguard against null/undefined defaultValue

        if (defaultValue) {
            const selectedOption = options.find(option => option.value === defaultValue);

            console.log({ options });


            if (selectedOption) {
                setSelectedOptions([selectedOption]); // Set selected option as an array
            }
        }
    }, [defaultValue, options]);

    useEffect(() => {
        if (isOpen && dropdownRef.current) {
            const dropdown = dropdownRef.current;
            const { bottom } = dropdown.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            if (bottom > viewportHeight) {
                dropdown.style.top = 'auto';
                dropdown.style.bottom = 'calc(100% - 14px)';
            } else {
                dropdown.style.top = 'calc(100% + 2px)';
                dropdown.style.bottom = 'auto';
            }

            if (selectedOptionRef.current && dropdownRef.current) {
                const dropdown = dropdownRef.current;
                const selectedOption = selectedOptionRef.current;

                const dropdownRect = dropdown.getBoundingClientRect();
                const selectedOptionRect = selectedOption.getBoundingClientRect();

                // Check if the selected option is within the visible area of the dropdown
                const isVisible = selectedOptionRect.top >= dropdownRect.top && selectedOptionRect.bottom <= dropdownRect.bottom;

                // If the selected option is not visible, scroll into view and offset
                if (!isVisible) {
                    selectedOption.scrollIntoView({
                        block: 'nearest',
                    });

                    // Apply offset
                    const offset = -100;
                    dropdown.scrollTop -= offset;
                }
            }


        }
    }, [isOpen]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleOptionClick = (option) => {
        if (isMultiple) {
            setSelectedOptions(prev =>
                prev.includes(option)
                    ? prev.filter(o => o !== option)
                    : [...prev, option]
            );
        } else {
            setSelectedOptions([option]);
            setIsOpen(false);
        }
        onSelect(isMultiple ? selectedOptions : option);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
        setTimeout(() => setIsOpen(false), 200); // Delay to allow click event on options
    };

    const selectedLabels = isMultiple
        ? selectedOptions.map(option => option.label).join(', ')
        : (selectedOptions && selectedOptions[0] ? selectedOptions[0].label : placeholder);

    const groupedOptions = isGrouped ? options.reduce((acc, option) => {
        if (!option.workspaceShortId) return acc;

        const workspaceGroup = acc.find(group => group.workspaceShortId === option.workspaceShortId);

        if (workspaceGroup) {
            workspaceGroup.boards.push(option);
        } else {
            acc.push({
                workspaceShortId: option.workspaceShortId,
                workspaceName: option.workspaceName,
                boards: [option]
            });
        }

        return acc;
    }, []) : [];

    const renderGroupedOptions = () => (
        <ul className="search-select-options" ref={dropdownRef}>
            {groupedOptions.map(group => (
                <li key={group.workspaceShortId} className="search-select-group">
                    <label className="search-select-group-label">
                        {group.workspaceName}
                    </label>
                    <ul className='search-select-option-list'>
                        {group.boards.map(option => (
                            <li
                                key={option.value}
                                className={`search-select-option${selectedOptions.includes(option) ? ' selected' : ''}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                <div className="selectable-option">
                                    <div dangerouslySetInnerHTML={{ __html: option.label }} />
                                </div>
                            </li>
                        ))}
                    </ul>
                </li>
            ))}
        </ul>
    );

    const renderNormalOptions = () => (
        <ul className="search-select-options" ref={dropdownRef}>
            {filteredOptions && filteredOptions.map(option => (
                <li
                    key={option.value}
                    className={`search-select-option${selectedOptions.includes(option) ? ' selected' : ''}`}
                    onClick={() => handleOptionClick(option)}
                    ref={selectedOptions.includes(option) ? selectedOptionRef : null} // Add ref to the selected option

                >
                    <div className="selectable-option">
                        <div dangerouslySetInnerHTML={{ __html: option.label }} />
                    </div>
                </li>
            ))}
        </ul>
    );

    return (
        <div className={`search-select ${isFocused ? 'focused' : ''}`} ref={selectRef}>
            <div
                className="search-select-trigger"
                onClick={() => {
                    setIsOpen(true);
                    setIsFocused(true);
                }}
            >
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onBlur={handleInputBlur}
                    placeholder={(selectedLabels ? selectedLabels.replace(/<br\s*\/?><span\s+className=["']current-option["']>\(current\)<\/span>/g, '') : placeholder)}

                />


                <span className="material-symbols-outlined">
                    keyboard_arrow_down
                </span>
            </div>
            {isOpen || true && (isGrouped ? renderGroupedOptions() : renderNormalOptions())}
            {/* {isOpen || true && (isGrouped ? renderGroupedOptions() : renderNormalOptions())} */}
        </div>
    );
};

export default SearchSelect;
