import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Fetch labels for a specific card
export const fetchCardLabels = createAsyncThunk(
    'labels/fetchCardLabels',
    async (cardId, thunkAPI) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/labels`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
          'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch labels');
            }

            const data = await response.json();
            return { cardId, labels: data.labels };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Fetch all labels for a board
export const fetchLabels = createAsyncThunk(
    'labels/fetchLabels',
    async (boardId, thunkAPI) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/boards/${boardId}/labels`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
          'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch labels');
            }

            const data = await response.json();
            return { boardId, labels: data.labels };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Optimistic update to add/remove labels
export const updateLabelOnCard = createAsyncThunk(
    'labels/updateLabelOnCard',
    async ({ cardId, labelId, boardId, isChecked }, { dispatch, getState, rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const method = isChecked ? 'PUT' : 'DELETE';
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/labels/${labelId}`, {
                method,
                headers: {
                    'Accept': 'application/json',
          'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ boardId }),
            });
            if (!response.ok) throw new Error(`Failed to ${isChecked ? 'add' : 'remove'} label`);
            return { labelId, isChecked };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const labelSlice = createSlice({
    name: 'labels',
    initialState: {
        labelsByCard: {},   // Store labels per card
        labelsByBoard: {},  // Store labels per board
        status: 'idle',
        error: null
    },
    reducers: {
        addLabelOptimistically: (state, action) => {
            const { boardId, newLabel } = action.payload;
            if (!state.labelsByBoard[boardId]) {
                state.labelsByBoard[boardId] = [];
            }
            state.labelsByBoard[boardId].push(newLabel);
        },
        updateLabelOptimistically: (state, action) => {
            const { boardId, updatedLabel } = action.payload;
            const index = state.labelsByBoard[boardId]?.findIndex(label => label._id === updatedLabel._id);
            if (index !== undefined && index >= 0) {
                state.labelsByBoard[boardId][index] = updatedLabel;
            }
        },
        removeLabelOptimistically: (state, action) => {
            const { boardId, labelId } = action.payload;
            const index = state.labelsByBoard[boardId]?.findIndex(label => label._id === labelId);
            if (index !== undefined && index >= 0) {
                state.labelsByBoard[boardId].splice(index, 1);
            }
        },
        toggleLabelOnCardOptimistically: (state, action) => {
            const { cardId, labelId, isChecked } = action.payload;
            const cardLabels = state.labelsByCard[cardId] || [];

            // Find the label object in the board's label list
            const label = Object.values(state.labelsByBoard)
                .flat()
                .find(l => l._id === labelId);

            if (!label) return; // If label is not found, exit early

            // Optimistically add or remove label
            if (isChecked) {
                state.labelsByCard[cardId] = [...cardLabels, label];
            } else {
                state.labelsByCard[cardId] = cardLabels.filter(l => l._id !== labelId);
            }
        },

    },
    extraReducers: (builder) => {
        builder
            // Handle board-wide labels fetching
            .addCase(fetchLabels.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLabels.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { boardId, labels } = action.payload;
                state.labelsByBoard[boardId] = labels;  // Store board labels by board ID
            })
            .addCase(fetchLabels.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Handle card-specific labels fetching
            .addCase(fetchCardLabels.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCardLabels.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { cardId, labels } = action.payload;
                state.labelsByCard[cardId] = labels;  // Store card labels by card ID
            })
            .addCase(fetchCardLabels.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateLabelOnCard.pending, (state, action) => {
                const { cardId, labelId, isChecked } = action.meta.arg;
                const cardLabels = state.labelsByCard[cardId] || [];

                // Find the label object in the board's label list
                const label = Object.values(state.labelsByBoard)
                    .flat()
                    .find(l => l._id === labelId);

                if (!label) return; // If label is not found, exit early

                // Optimistically toggle the label state
                if (isChecked) {
                    state.labelsByCard[cardId] = [...cardLabels, label];
                } else {
                    state.labelsByCard[cardId] = cardLabels.filter(l => l._id !== labelId);
                }
            })
            .addCase(updateLabelOnCard.rejected, (state, action) => {
                const { cardId, labelId, isChecked } = action.meta.arg;
                const cardLabels = state.labelsByCard[cardId] || [];

                // Find the label object in the board's label list
                const label = Object.values(state.labelsByBoard)
                    .flat()
                    .find(l => l._id === labelId);

                if (!label) return; // If label is not found, exit early

                // Rollback based on the isChecked state
                if (isChecked) {
                    state.labelsByCard[cardId] = cardLabels.filter(l => l._id !== labelId);
                } else {
                    state.labelsByCard[cardId] = [...cardLabels, label];
                }

                state.error = action.payload;
            })

    }
});

export const { addLabelOptimistically, updateLabelOptimistically, removeLabelOptimistically, toggleLabelOnCardOptimistically } = labelSlice.actions;

export default labelSlice.reducer;
