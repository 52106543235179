// src/components/Checklist.js

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    fetchChecklists,
    addChecklistItem,
    deleteChecklistItem,
    deleteChecklist,
    localDeleteChecklist,
    localAddItem,
    localUpdateItem,
    localDeleteItem
} from '../../redux/Slices/checklistSlice';

import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';


import IconChecklist from '../icons/IconChecklist';

import './scss/Checklists.scss';
import Checkbox from '../Global/Checkbox';
import { config } from '../../config';
import Spinner from '../Global/Spinner';
import { marked } from 'marked';

const Checklists = ({ cardId }) => {
    const dispatch = useDispatch();
    const [newItem, setNewItem] = useState('');
    const [editingItemId, setEditingItemId] = useState(null);
    const [editItemTitle, setEditItemTitle] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [visibleAddNew, setVisibleAddNew] = useState(null); // Track which checklist's new item input is visible
    const [hideCheckedItems, setHideCheckedItems] = useState(false); // Track whether to hide checked items
    const [isChecked, setIsChecked] = useState(false);
    const [isTextareaFocused, setIsTextareaFocused] = useState(false); // New focus state

    const { handlePopoverClick } = useHandlePopoverClick();

    const checklistItemRef = useRef(null);


    const { checklists, status, error } = useSelector((state) => state.checklists);


    useEffect(() => {
        dispatch(fetchChecklists(cardId));
    }, [dispatch, cardId]);

    const updateChecklistItem = async ({ cardId, checklistId, itemId, title, checked }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/checklists/${checklistId}/items/${itemId}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ title, checked })
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            return data.checklists;
        } catch (error) {
            console.error('Error updating checklist item:', error.message);
            throw error;
        }
    };


    const handleCheck = (checklistId, itemId, itemTitle) => {

        // Optimistic update
        const item = checklists.find(checklist => checklist._id === checklistId).items.find(item => item._id === itemId);
        dispatch(localUpdateItem({ checklistId, itemId: itemId, title: itemTitle, checked: !item.checked }));

        // Actual API call
        updateChecklistItem({
            cardId,
            checklistId,
            itemId: itemId,
            title: itemTitle,
            checked: !item.checked
        });


    };


    const handleAddItem = (checklistId) => {
        if (newItem.trim()) {
            // Find the checklist and add the new item to the list of items
            const updatedChecklists = checklists.map(checklist => {
                if (checklist._id === checklistId) {
                    return {
                        ...checklist,
                        items: [...checklist.items, { _id: new Date().toISOString(), title: newItem, checked: false }] // Temporary item added locally
                    };
                }
                return checklist;
            });

            // Dispatch a local state update to optimistically update the UI
            dispatch({
                type: 'checklists/localUpdate',
                payload: updatedChecklists
            });

            dispatch(localAddItem({ checklistId, newItem }));

            // Dispatch the actual action to add the new item to the backend
            dispatch(addChecklistItem({ cardId, checklistId, newItem }));

            // Clear the new item input field but keep it visible for adding another item
            setNewItem('');
            setVisibleAddNew(checklistId);  // Keep the input open for the same checklist
        }
    };



    const updateItemTitle = (checklistId, itemId, title, checked) => {
        if (title) {
            // Optimistic update
            dispatch(localUpdateItem({ checklistId, itemId, title, checked }));
            updateChecklistItem({ cardId, checklistId, itemId, title, checked });
            setIsEditing(false);
            setEditingItemId(null);
            setEditItemTitle('');
        }
    };

    const handleDeleteChecklist = (e, checklistId) => {
        handlePopoverClick(e, 'deleteAction', { type: 'CHECKLIST', id: checklistId, cardId });

    };

    const handleItemMore = (e, checklistId, item) => {
        handlePopoverClick(e, 'more', { type: 'CHECKLIST_ITEM_MORE', checklistId, item, cardId });
    }

    useEffect(() => {
        if (checklistItemRef.current) {
            checklistItemRef.current.style.height = '22px';
            checklistItemRef.current.style.height = `${checklistItemRef.current.scrollHeight}px`;
        }
    }, [editItemTitle, newItem]);

    useEffect(() => {
        if (checklistItemRef.current) {
            checklistItemRef.current.focus();
            checklistItemRef.current.select();
        }
    }, [visibleAddNew, editingItemId]);

    const progress = (items) => items?.length ? (items?.filter(item => item?.checked).length / items.length) * 100 : 0;

    if (status === 'loading') {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner size={30} color="#3498db" speed={1.5} />
            </div>
        );
    }

    return (
        checklists && (
            checklists.map(checklist => (
                <div className="zoobbe-checklist" key={checklist._id}>
                    <div className="checklist-header">
                        <h3>
                            <span className="material-symbols-outlined">
                                checklist
                            </span>
                            {checklist.title}
                        </h3>

                        <div className="checklist-more-buttons">
                            <button
                                className="hide-checked-item-btn"
                                onClick={() => setHideCheckedItems(!hideCheckedItems)}
                            >
                                {hideCheckedItems ? (
                                    <span className="material-symbols-outlined">radio_button_partial</span>
                                ) : (
                                    <span className="material-symbols-outlined">radio_button_unchecked</span>
                                )}
                                {hideCheckedItems ? 'Show all items' : 'Hide checked items'}
                            </button>

                            <button
                                className="checklist-delete-btn"
                                onClick={(e) => handleDeleteChecklist(e, checklist._id)}
                                data-popover-trigger
                            >
                                <span className="material-symbols-outlined">
                                    delete
                                </span>
                                Delete
                            </button>
                        </div>
                    </div>
                    <div className="progress-bar">
                        <span>{Math.round(progress(checklist.items))}%</span>
                        <div className='checklist-progress-bar'>
                            <div className="progress" style={{ width: `${progress(checklist.items)}%` }}></div>
                        </div>
                    </div>
                    <div className="checklist-items">
                        {checklist.items.filter(item => !hideCheckedItems || !item.checked).map(item => (
                            item && item._id && (
                                <div className={`checklist-item ${(isEditing && editingItemId === item._id) ? 'currently-editing' : ''} ${item.checked ? 'checked' : ''}`} key={item._id}>

                                    <Checkbox
                                        checked={item.checked}
                                        id={`item-${item._id}`}
                                        onChange={() => {
                                            handleCheck(checklist._id, item._id, item.title)
                                        }}

                                    />


                                    {editingItemId !== item._id ? (
                                        <div className='checklist-label'>
                                            <label
                                                onClick={() => {
                                                    setEditingItemId(item._id);
                                                    setEditItemTitle(item.title);
                                                    setIsEditing(true);
                                                }}
                                                dangerouslySetInnerHTML={{ __html: marked(item.title) }}
                                            ></label>

                                            <span className="material-symbols-outlined" id={`popover-checklist-item-more-${item._id}`} onClick={(e) => handleItemMore(e, checklist._id, item)} data-popover-trigger>
                                                more_horiz
                                            </span>
                                            {/* <button className="delete-item-btn" onClick={() => handleDeleteItem(checklist._id, item._id)}>Delete</button> */}
                                        </div>
                                    ) : (
                                        <div className="checklist-footer-container">
                                            <div className="add-item">
                                                <div className={`textarea-wrapper ${isTextareaFocused ? 'focused' : ''}`}>
                                                    <textarea
                                                        type="text"
                                                        ref={checklistItemRef}
                                                        placeholder="Add an item"
                                                        value={editItemTitle}
                                                        onChange={(e) => setEditItemTitle(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                updateItemTitle(checklist._id, item._id, editItemTitle, item.checked);
                                                            }
                                                        }}
                                                        rows={1}
                                                        style={{ overflow: 'hidden' }}
                                                        onFocus={() => setIsTextareaFocused(true)}  // Set focus state
                                                        onBlur={() => setIsTextareaFocused(false)}   // Remove focus state
                                                    />
                                                </div>
                                            </div>
                                            <div className="checklist-footer">
                                                <div className='left-button'>
                                                    <button className="add-btn" onClick={() => updateItemTitle(checklist._id, item._id, editItemTitle, item.checked)}>Save</button>
                                                    <button className="cancel-btn" onClick={() => {
                                                        setEditingItemId(null);
                                                        setEditItemTitle('');
                                                        setIsEditing(false);
                                                    }}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414z" fill="currentColor"></path></svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        ))}
                        {visibleAddNew === checklist._id ? (
                            <div className="checklist-footer-container">
                                <div className="add-item">
                                    <div className={`textarea-wrapper ${isTextareaFocused ? 'focused' : ''}`}>

                                        <textarea
                                            type="text"
                                            ref={checklistItemRef}
                                            placeholder="Add an item"
                                            value={newItem}
                                            onChange={(e) => setNewItem(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    handleAddItem(checklist._id);
                                                }
                                            }}
                                            rows={1}
                                            style={{ overflow: 'hidden' }}
                                            onFocus={() => setIsTextareaFocused(true)}  // Set focus state
                                            onBlur={() => setIsTextareaFocused(false)}   // Remove focus state
                                        />
                                    </div>
                                </div>
                                <div className="checklist-footer">
                                    <div className='left-button'>
                                        <button className="add-btn" onClick={() => handleAddItem(checklist._id)}>Add</button>
                                        <button className="cancel-btn" onClick={() => { setNewItem(''); setVisibleAddNew(null); }}>Discard</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <button className="add-new-item-btn" onClick={() => setVisibleAddNew(checklist._id)}>Add item</button>
                        )}
                    </div>
                </div >
            ))
        )
    );
};

export default Checklists;
