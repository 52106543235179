import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { config } from '../../config';
import './index.scss';

const RecoveryMail = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [buttonText, setButtonText] = useState('Send Recovery Link');
  const navigate = useNavigate();

  const handleRecoveryMail = async e => {
    e.preventDefault();

    setIsSubmit(true);
    setButtonText('Sending...');

    try {
      const response = await fetch(`${config.API_URI}/api/users/recovery`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message);
        setMessage(null);
      } else {
        const data = await response.json();
        setMessage(data.message);
        setError(null);

        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError('An error occurred while sending the recovery email');
      setMessage(null);
    } finally {
      setIsSubmit(false);
      setButtonText('Send Recovery Link');
    }
  };

  return (
    <>
      <Helmet>
        <title>Password Recovery | Zoobbe</title>
      </Helmet>

      <div className="reset-container">
        <div className="reset-box">
          <h1 className="reset-logo">Zoobbe</h1>
          <h2 className="reset-heading">Can't login?</h2>
          <form className="reset-form" onSubmit={handleRecoveryMail}>
            <span className="label">We'll send a recovery link to</span>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="reset-input"
              required
            />
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="reset-button" disabled={isSubmit}>
              {buttonText}
            </button>
          </form>
          <div className="reset-links">
            <Link to="/login" className="reset-link">Return to Log in</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoveryMail;
