import React, { useState } from 'react';
import Unsplash from './Unsplash';
import Custom from './Custom';

const Screens = ({ activeLabel, setActiveLabel, activeScreen, setActiveScreen, handleBack, screenHistory, setScreenHistory }) => {

    // Function to navigate to a screen
    const handleItemClick = (screen, label) => {
        // Update history and navigate to the new screen
        setScreenHistory([...screenHistory, { screen: activeScreen, label: activeLabel }]);
        setActiveScreen(screen);
        setActiveLabel(label);
    };



    // Sub-screens content
    const renderScreenContent = () => {
        return (
            <div className="add-attachment">
                <div className="group">
                    <div className="unsplash-images background-image-screens">
                        <div className="board-background-select" onClick={() => {
                            handleItemClick('Photos');
                            setActiveLabel('Photos');
                        }}>
                            <span className="background-box photos">Photos</span>
                        </div>
                        <div className="board-background-select" onClick={() => {
                            handleItemClick('Colors');
                            setActiveLabel('Colors');
                        }}>
                            <span className="background-box colors">Colors</span>
                        </div>
                        <div className="board-background-select" onClick={() => {
                            handleItemClick('Custom');
                            setActiveLabel('Custom');
                        }}>
                            <span className="background-box custom">Custom</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className="screens-container">
            {/* Render screen content */}
            {renderScreenContent()}
        </div>
    );
};

export default Screens;
