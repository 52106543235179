import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { config } from '../../config';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { showToast } from '../../redux/Slices/toastSlice';

import './scss/More.scss';
import { deleteChecklistItem, localDeleteItem } from '../../redux/Slices/checklistSlice';
import { fetchBoardById } from '../../redux/Slices/boardSlice';


const More = (props) => {
    const inputRef = useRef(null);
    const { card } = useSelector((state) => state.card);
    const { type, cardId, checklistId, item } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const handleDeleteItem = async () => {
        // Optimistic delete
        dispatch(localDeleteItem({ checklistId, itemId: item._id }));

        // Actual API call
        dispatch(deleteChecklistItem({ cardId, checklistId, itemId: item._id }));
        dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));


    };

    const handleConverToCard = async () => {
        try {
            // Step 1: Create a new card using API
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/cards`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    actionListId: card.actionList,
                    title: item.title,
                }),
            });

            if (!response.ok) throw new Error('Failed to add card');

            const data = await response.json();
            const newCard = data.card;

            dispatch(fetchBoardById(newCard.board));

            // Step 2: Delete the checklist item if card creation was successful
            handleDeleteItem();

            // Optional: Show a success toast or message
            dispatch(showToast({ message: 'Item converted to card successfully', type: 'success' }));
        } catch (error) {
            console.error('Error converting item to card:', error);
            dispatch(showToast({ message: 'Failed to convert item to card', type: 'error' }));
        }
    };

    return (
        <div className="more-action">
            <h2>Item actions</h2>

            <ul className="group">
                <li onClick={handleConverToCard} className='conver-to-card'>
                    <span className="material-symbols-outlined">
                        convert_to_text
                    </span>
                    Convert to card</li>
                <li onClick={handleDeleteItem} className='delete-item'>
                    <span className="material-symbols-outlined">
                        delete
                    </span>
                    Delete</li>
            </ul>
        </div>


    );
};

export default More;
