import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { showToast } from '../../redux/Slices/toastSlice';
import { config } from '../../config';

import Spinner from '../Global/Spinner';
import ProfileNavbar from '../Profile/ProfileNavbar';

const SetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [name, setName] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const accessToken = localStorage.getItem('accessToken');

    const isPasswordStrong = (password) => {
        return (
            password.length >= 8 &&
            /[A-Z]/.test(password) &&
            /[a-z]/.test(password) &&
            /\d/.test(password) &&
            /[!@#$%^&*(),.?":{}|<>]/.test(password)
        );
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        setName(value);
    };
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);

        if (!isPasswordStrong(value)) {
            setPasswordError(
                'Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character.'
            );
        } else {
            setPasswordError(null);
        }
    };

    const handleSetPassword = async (e) => {
        e.preventDefault();

        if (!accessToken) {
            dispatch(showToast({ message: 'Authentication error: User not logged in.', type: 'error' }));
            return;
        }

        if (newPassword !== confirmPassword) {
            dispatch(showToast({ message: 'Passwords do not match.', type: 'error' }));
            return;
        }

        if (passwordError) return;

        setLoading(true);

        try {
            const response = await fetch(`${config.API_URI}/api/users/set-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ token: accessToken, email: user?.user?.email, newPassword }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                dispatch(showToast({ message: errorData.message || 'Failed to set password.', type: 'error' }));
                return;
            }

            dispatch(showToast({ message: 'Password set successfully! Redirecting...', type: 'success' }));
            setTimeout(() => {
                window.location.href = '/'
            }, 2000);
        } catch (error) {
            dispatch(showToast({ message: 'An unexpected error occurred.', type: 'error' }));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="profile-page set-">
            <div className="bottom-profile-content">
                <div className="content">
                    <h1>Welcome to Zoobbe!</h1>
                    <p>
                        Please set your password to secure your account and complete the setup. A strong password ensures your account stays protected from unauthorized access.
                    </p>
                    <div className="about-section">
                        <h2>Set Password</h2>
                        <form onSubmit={handleSetPassword}>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={user?.user?.email}
                                    required
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label>New Password</label>
                                <input
                                    type="password"
                                    placeholder="Enter a strong password"
                                    value={newPassword}
                                    onChange={handlePasswordChange}
                                    required
                                />
                                {passwordError && <p className="error-message">{passwordError}</p>}
                            </div>
                            <div className="form-group">
                                <label>Confirm Password</label>
                                <input
                                    type="password"
                                    placeholder="Re-enter your password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" disabled={loading || passwordError}>
                                {loading ? <Spinner size={20} color="#fff" strokeWidth={7} /> : 'Set Password'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetPassword;
