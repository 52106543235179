// snackbarSlice.js
import { createSlice } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        isOpen: false,
        message: '',
        type: 'info', // can be 'info', 'success', 'error', etc.
    },
    reducers: {
        showSnackBar: (state, action) => {
            state.isOpen = true;
            state.message = action.payload.message;
            state.type = action.payload.type || 'info';
        },
        hideSnackBar: (state) => {
            state.isOpen = false;
            state.message = '';
        },
    },
});

export const { showSnackBar, hideSnackBar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
