import React, { useState } from 'react';
import './scss/BoardMenu.scss';
import Screens from './screens/Screens';
import Unsplash from './screens/Unsplash';
import Custom from './screens/Custom';

const menuData = {
    menu: [
        {
            title: "Main",
            items: [
                { icon: "info", label: "About this board", screen: "About" },
                { icon: "history", label: "Activity", screen: "Activity" },
                { icon: "archive", label: "Archived items", screen: "Archived" }
            ]
        },
        {
            title: "Board Settings",
            items: [
                { icon: "settings", label: "Settings", screen: "Settings" },
                { icon: "image", label: "Change background", screen: "Background" },
                { icon: "category", label: "Custom Fields", screen: "CustomFields" },
                { icon: "flash_on", label: "Automation", screen: "Automation" },
                { icon: "extension", label: "Power-Ups", screen: "PowerUps" },
                { icon: "label", label: "Labels", screen: "Labels" },
                { icon: "emoji_objects", label: "Stickers", screen: "Stickers" },
                { icon: "new_window", label: "Make template", screen: "Template" }
            ]
        },
        {
            title: "Board Utilities",
            items: [
                { icon: "visibility", label: "Watch", screen: "Watch" },
                { icon: "mail", label: "Email-to-board", screen: "EmailToBoard" },
                { icon: "content_copy", label: "Copy board", screen: "CopyBoard" },
                { icon: "share", label: "Print, export, and share", screen: "Share" },
                { icon: "close", label: "Close board", screen: "CloseBoard" }
            ]
        }
    ]
};

const BoardMenu = () => {
    const [activeScreen, setActiveScreen] = useState(null);
    const [activeLabel, setActiveLabel] = useState('Menu');
    const [screenHistory, setScreenHistory] = useState([]); // Track screen history

    const handleBack = () => {
        if (screenHistory.length > 0) {
            const previousScreen = screenHistory.pop();
            setActiveScreen(previousScreen.screen);
            setActiveLabel(previousScreen.label);
            setScreenHistory([...screenHistory]); // Update history after pop
        } else {
            setActiveScreen(null); // Reset to main menu
            setActiveLabel('Menu');
        }
    };

    const handleItemClick = (screen, label) => {
        // Push current screen to history before navigating to a new screen
        setScreenHistory([...screenHistory, { screen: activeScreen, label: activeLabel }]);
        setActiveScreen(screen);
        setActiveLabel(label);
    };

    const screens = {
        About: () => <div className="screen">Coming soon</div>,
        Activity: () => <div className="screen">Coming soon</div>,
        Archived: () => <div className="screen">Coming soon</div>,
        Settings: () => <div className="screen">Coming soon</div>,
        Background: () => <Screens
            activeScreen={activeScreen}
            activeLabel={activeLabel}
            screenHistory={screenHistory}
            setActiveScreen={setActiveScreen}
            setActiveLabel={setActiveLabel}
            setScreenHistory={setScreenHistory}
        />,
        Photos: () => <Unsplash />,
        Colors: () => <div className="screen">Coming soon</div>,
        Custom: () => <Custom />,
        CustomFields: () => <div className="screen">Coming soon</div>,
        Automation: () => <div className="screen">Coming soon</div>,
        PowerUps: () => <div className="screen">Coming soon</div>,
        Labels: () => <div className="screen">Coming soon</div>,
        Stickers: () => <div className="screen">Coming soon</div>,
        Template: () => <div className="screen">Coming soon</div>,
        Watch: () => <div className="screen">Coming soon</div>,
        EmailToBoard: () => <div className="screen">Coming soon</div>,
        CopyBoard: () => <div className="screen">Coming soon</div>,
        Share: () => <div className="screen">Coming soon</div>,
        CloseBoard: () => <div className="screen">Coming soon</div>
    };

    return (
        <div className={`menu-container`}>
            <div className={`menu-content`}>
                {!activeScreen ? (
                    <>
                        <div className="menu-header">
                            <h3 className="menu-title">{activeLabel}</h3>
                        </div>
                        {menuData.menu.map((section, sectionIndex) => (
                            <div className={`menu-inner-content`} key={sectionIndex}>
                                <ul className="menu-section">
                                    {section.items.map((item, itemIndex) => (
                                        <li
                                            className="menu-item"
                                            key={itemIndex}
                                            onClick={() => handleItemClick(item.screen, item.label)}
                                        >
                                            <span className="menu-icon material-symbols-outlined">{item.icon}</span>
                                            <span>{item.label}</span>
                                        </li>
                                    ))}
                                </ul>
                                {sectionIndex < menuData.menu.length - 1 && <div className="menu-divider"></div>}
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="menu-screen">
                        <div className="menu-header">
                            <button className="back-button" onClick={handleBack}>
                                <span className="material-symbols-outlined">keyboard_backspace</span>
                            </button>
                            <h3 className="menu-title">{activeLabel}</h3>
                        </div>

                        {screens[activeScreen]()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BoardMenu;
