import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './scss/SkeletonCardDetails.scss';

const SkeletonCardDetails = () => {
    return (
        <div className="zoobbe-card-wrapper skeleton">
            <div className='zoobbe-card-modal-container'>
                <div className="zoobbe-card-details">
                    <div className="zoobbe-card-content">
                        <div className="zoobbe-card-details-body">
                            <div className="zoobbe-card-details-header">
                                <div className='card-heading'>
                                    <Skeleton circle width={35} height={35} />
                                    <Skeleton width={570} height={30} />
                                    <div className="close-card-container" style={{position: 'static'}}>
                                        <Skeleton width={30} height={30} />
                                    </div>
                                </div>
                                <div className="zoobbe-card-details-status">
                                    <Skeleton width={200} />
                                </div>
                            </div>

                            <div className="zoobbe-card-details-info">
                                <div className='zoobbe-card-details-info-left'>
                                    {/* Members, Labels, Notifications */}
                                    <div className='card-details-top'>
                                        <div className='card-members'>
                                            <Skeleton circle width={30} height={30} />
                                            <Skeleton circle width={30} height={30} />
                                            <Skeleton circle width={30} height={30} />
                                        </div>

                                        <div className='watchers'>
                                            <Skeleton width={80} height={30} />
                                            <Skeleton width={30} height={30} />
                                        </div>

                                        <Skeleton width={80} height={30} />

                                    </div>

                                    {/* Due Date */}
                                    <div className="zoobbe-duedate">
                                        <Skeleton width={100} height={20} />
                                        <Skeleton width={200} height={30} />
                                    </div>

                                    {/* Description */}
                                    <div className="zoobbe-description">
                                        <Skeleton width={80} height={15} />
                                        <Skeleton count={5} height={8} />
                                    </div>

                                    {/* Attachments */}
                                    <div className="zoobbe-attachment">
                                        <Skeleton width={50} height={50} />
                                        <Skeleton count={3} width={370} height={8} />
                                    </div>
                                    <div className="zoobbe-attachment">
                                        <Skeleton width={50} height={50} />
                                        <Skeleton count={3} width={370} height={8} />
                                    </div>

                                    {/* Checklist */}
                                    <Skeleton width={200} height={20} />

                                    {/* Comments */}
                                    <div className="zoobbe-card-details-footer-content">
                                        <div className="zoobbe-card-content" style={{paddingLeft: '0px'}}>
                                            <Skeleton width={30} height={30} />
                                            <Skeleton width={350} height={30} />
                                        </div>
                                        <Skeleton width={390} height={8} count={3} />

                                    </div>
                                </div>

                                <div className='zoobbe-card-details-info-right'>
                                    <Skeleton width={150} height={30} />
                                    <Skeleton width={150} height={30} />
                                    <Skeleton width={150} height={30} />
                                    <Skeleton width={150} height={30} />
                                    <Skeleton width={150} height={30} />
                                    <Skeleton width={150} height={30} />
                                    <Skeleton width={150} height={30} />
                                    <Skeleton width={150} height={30} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonCardDetails;
