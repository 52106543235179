import { createSlice } from '@reduxjs/toolkit';

const mentionedSlice = createSlice({
    name: 'mentioned',
    initialState: {
        mentionedMemberIds: [],
    },
    reducers: {
        mentionedMemberIds(state, action) {
            const memberId = action.payload;

            console.log(memberId);
            
            if (!state.mentionedMemberIds.includes(memberId)) {
                state.mentionedMemberIds.push(memberId);
            }
        },
        resetMentionedIds(state) {
            state.mentionedMemberIds = [];
        },
    },
});

export const { mentionedMemberIds, resetMentionedIds } = mentionedSlice.actions;
export default mentionedSlice.reducer;
