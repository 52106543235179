import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './scss/SkeletonBoard.scss';

const SkeletonRow = ({ itemCount, itemHeight = 100 }) => {
    return (
        <div className='zoobbe-action skeleton'>

            <div className='skeleton-inner'>
                <Skeleton height={20} width={230} style={{ marginBottom: '5px', borderRadius: '15px' }} />
                <Skeleton height={10} width={200} style={{ marginBottom: '10px', borderRadius: '15px' }} />

                <Skeleton height={50} width={270} style={{ marginBottom: '10px', borderRadius: '15px' }} />

                {[...Array(itemCount)].map((_, index) => (
                    <Skeleton
                        key={index}
                        height={itemHeight}
                        width={270}
                        style={{ marginBottom: '10px', borderRadius: '15px' }}
                    />
                ))}

                <Skeleton height={50} width={270} style={{ marginBottom: '10px', borderRadius: '15px' }} />

                <div className='add-list-skeleton'>
                    <Skeleton height={35} width={220} style={{ marginBottom: '10px', borderRadius: '15px' }} />
                    <Skeleton height={35} width={40} style={{ marginBottom: '10px', borderRadius: '15px' }} />
                </div>
            </div>
        </div>
    );
};

const SkeletonBoard = () => {
    return (
        <div className={`zoobbe-workspace-board-container`} style={{ margin: 0 }}>
            <div className='header-wrapper'>
                <div className='skeleton-board-header'>
                    <div className='board-header-left'>
                        <Skeleton height={30} width={200} />
                        ★
                        <Skeleton height={30} width={100} />
                    </div>
                    <div className='board-header-right'>
                        <Skeleton height={35} width={150} />

                        <Skeleton height={35} width={35} style={{ borderRadius: '50px' }} />
                        <Skeleton height={35} width={35} style={{ borderRadius: '50px' }} />
                        <Skeleton height={35} width={35} style={{ borderRadius: '50px' }} />
                        <Skeleton height={35} width={80} />

                    </div>
                </div>
            </div>
            <div className='zoobbe-board-skeleton'>
                <SkeletonRow itemCount={3} itemHeight={80} />
                <SkeletonRow itemCount={2} />
                <SkeletonRow itemCount={1} />
                <SkeletonRow itemCount={3} />
                <SkeletonRow itemCount={4} itemHeight={80} />
            </div>
        </div>
    );
};

export default SkeletonBoard;
