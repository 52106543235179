import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../redux/Slices/toastSlice';
import ZoobbeSelect from '../Global/ZoobbeSelect';
import './scss/CreateWorkspace.scss';
import { useNavigate } from 'react-router-dom';
import { toSlug } from '../../utils/helpers';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { closeModal } from '../../redux/Slices/modalSlice';
import { config } from '../../config';

const CreateBoard = ({ defaultSelectedOption }) => {
  const { workspaces } = useSelector(state => state.workspaces);
  const navigate = useNavigate();
  const [boardTitle, setBoardTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const workspaceOptions = workspaces?.map(workspace => ({
    value: workspace._id,
    label: workspace.name
  }));

  const visibilityOptions = [
    { value: 'workspace', label: 'Workspace' },
    { value: 'public', label: 'Public' },
    { value: 'private', label: 'Private' },
  ];

  const [selectedWorkspace, setSelectedWorkspace] = useState(workspaceOptions[defaultSelectedOption]);
  const [selectedVisibility, setSelectedVisibility] = useState(visibilityOptions[0]);

  const dispatch = useDispatch();

  const handleCreateBoard = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(config.API_URI + `/api/boards`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          workspaceId: selectedWorkspace.value,
          title: boardTitle,
        }),
      });

      if (!response.ok) {
        dispatch(showToast({ message: 'Failed to create board', type: 'error' }));
        throw new Error('Failed to create board.');
      }
      const data = await response.json();
      dispatch(showToast({ message: data.message, type: 'success' }));
      dispatch(closeModal());
      dispatch(fetchWorkspaces());

      navigate(data.board.permalink);

    } catch (error) {
      dispatch(showToast({ message: 'Error creating board: ' + error, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleWorkspaceSelect = (workspace) => {
    setSelectedWorkspace(workspace);
    console.log('Selected Workspace:', workspace);
  };

  const handleVisibilitySelect = (visibility) => {
    setSelectedVisibility(visibility);
    console.log('Selected visibility:', visibility);
  };

  return (
    <div className="create-board-form">
      <h1>Create Board</h1>
      <form onSubmit={handleCreateBoard}>
        <label htmlFor="workspace-name">Board Title*</label>
        <input
          type="text"
          id="workspace-name"
          value={boardTitle}
          onChange={(e) => setBoardTitle(e.target.value)}
          placeholder="Zoobbe Development"
          required
        />

        <label htmlFor="workspace-type">Workspace</label>
        <ZoobbeSelect
          options={workspaceOptions}
          defaultSelectedOption={defaultSelectedOption}
          onSelect={handleWorkspaceSelect}
        />

        <label htmlFor="workspace-type">Visibility</label>
        <ZoobbeSelect
          options={visibilityOptions}
          defaultSelectedOption={0}
          onSelect={handleVisibilitySelect}
        />

        <button type="submit" disabled={loading}>
          {loading ? 'Creating...' : 'Create'}
        </button>
      </form>
    </div>
  );
};

export default CreateBoard;
