import { configureStore } from '@reduxjs/toolkit';
import boardIdReducer from './Slices/boardIdSlice.js';
import modalReducer from './Slices/modalSlice';
import toastReducer from './Slices/toastSlice';
import snackbarReducer from './Slices/snackbarSlice.js';
import usersReducer from './Slices/usersSlice';
import userReducer from './Slices/userSlice';
import boardMembersReducer from './Slices/boardMembersSlice.js';
import cardMemberReducer from './Slices/cardMemberSlice.js';
import workspaceReducer from './Slices/workspaceSlice.js';
import boardReducer from './Slices/boardSlice.js';
import boardsReducer from './Slices/boardsSlice.js';
import popoverReducer from './Slices/popoverSlice.js';
import checklistReducer from './Slices/checklistSlice.js';
import attachmentReducer from './Slices/attachmentSlice.js';
import labelReducer from './Slices/labelSlice.js';
import memberReducer from './Slices/memberSlice.js';
import cardReducer from './Slices/cardSlice.js';
import mentionedReducer from './Slices/mentionedSlice.js';
import filterQueryReducer from './Slices/filterQuerySlice.js';
import actionListReducer from './Slices/actionlistSlice.js';
import cardsByMemberIdReducer from './Slices/cardByMemberIdSlice.js';
import activitiesReducer from './Slices/activitiesSlice.js';

const store = configureStore({
  reducer: {
    boardId: boardIdReducer,
    modal: modalReducer,
    toast: toastReducer,
    snackbar: snackbarReducer,
    users: usersReducer,
    user: userReducer,
    cardMembers: cardMemberReducer,
    boardMembers: boardMembersReducer,
    workspaces: workspaceReducer,
    board: boardReducer,
    boards: boardsReducer,
    popover: popoverReducer,
    checklists: checklistReducer,
    attachments: attachmentReducer,
    labels: labelReducer,
    member: memberReducer,
    card: cardReducer,
    mentioned: mentionedReducer,
    filterQuery: filterQueryReducer,
    actionList: actionListReducer,
    cardsByMemberId: cardsByMemberIdReducer,
    activities: activitiesReducer,

    // Add other reducers as needed
  },
});

export default store;
