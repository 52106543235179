import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../redux/Slices/modalSlice';
import ShareBoardModal from '../Workspaces/ShareBoard';
import CreateWorkspace from '../Profile/CreateWorkspace';
import CreateBoard from '../Profile/CreateBoard';
import InviteWorkspace from '../Workspaces/InviteWorkspace';

const ModalManager = () => {
    const dispatch = useDispatch();
    const { isOpen, modalType, modalData } = useSelector((state) => state.modal);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [isOpen]);

    const handleClose = () => {
        dispatch(closeModal());
    };

    if (!isOpen) return null;

    let ModalContent;
    switch (modalType) {
        case 'SHARE_BOARD':
            ModalContent = <ShareBoardModal />;
            break;
        case 'CREATE_WORKSPACE':
            ModalContent = <CreateWorkspace />;
            break;
        case 'CREATE_BOARD':
            ModalContent = <CreateBoard defaultSelectedOption={modalData.defaultSelectedOption} />;
            break;
        case 'INVITE_WORKSPACE':
            ModalContent = <InviteWorkspace workspaceId={modalData.workspaceId} />;
            break;
        case 'SET_PASSWORD':
            ModalContent = <InviteWorkspace workspaceId={modalData.workspaceId} />;
            break;
        default:
            ModalContent = null;
    }

    return (
        <div className="modal-overlay" onClick={handleClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                {ModalContent}
            </div>
        </div>
    );
};

export default ModalManager;
