import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updatePopoverPosition } from '../redux/Slices/popoverSlice';
import { getScrollableAncestor } from '../utils/helpers';

const usePopoverPositionUpdater = (targetId, popoverRef) => {
    const dispatch = useDispatch();

    const updatePosition = useCallback(() => {
        const target = document.getElementById(targetId); // Use target ID to get the DOM element
        if (!target || !popoverRef.current) return;

        const rect = target.getBoundingClientRect();
        const popoverRect = popoverRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let top = rect.bottom + window.scrollY + 4;
        let left = rect.left + window.scrollX;

        // Adjust position if out of viewport bounds
        if (left + popoverRect.width > viewportWidth) {
            left = rect.right - popoverRect.width + window.scrollX;
        }
        if (top + popoverRect.height > viewportHeight) {
            top = rect.top - popoverRect.height + window.scrollY - 4;
        }
        if (left < 0) {
            left = rect.left + window.scrollX;
        }
        if (top < 0) {
            top = rect.bottom + window.scrollY;
        }

        // Final adjustments to prevent overflow
        left = Math.max(0, Math.min(left, viewportWidth - popoverRect.width));
        top = Math.max(0, Math.min(top, viewportHeight - popoverRect.height));

        dispatch(updatePopoverPosition({ top, left }));
    }, [dispatch, targetId, popoverRef]);

    useEffect(() => {
        if (!targetId) return;

        const target = document.getElementById(targetId);
        const scrollableAncestor = getScrollableAncestor(target);

        // Update position immediately on mount
        updatePosition();

        // Event listeners for scroll and resize
        const handleScroll = updatePosition;
        const handleResize = updatePosition;

        scrollableAncestor?.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            scrollableAncestor?.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [targetId, updatePosition]);

    // Optional: Handle changes to targetId or popoverRef separately
    useEffect(() => {
        updatePosition();
    }, [targetId, popoverRef, updatePosition]);
};

export default usePopoverPositionUpdater;
