import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { config } from '../../config';

const Verify = () => {
    const [status, setStatus] = useState('Verifying...');
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');

    useEffect(() => {
        if (token) {
            fetch(`${config.API_URI}/api/users/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        // Redirect to verification success page
                        setTimeout(() => {
                            window.location.href = '/verification-success'; // Redirect to success page
                        }, 2000);
                    } else {
                        setStatus('Verification failed. Token may be invalid or expired.');
                    }
                })
                .catch(error => {
                    console.error('Verification error:', error);
                    setStatus('An error occurred during verification.');
                });
        } else {
            setStatus('Invalid token.');
        }
    }, [token]);

    return (
        <div className="verify-container">
            <h1>{status}</h1>
        </div>
    );
};

export default Verify;
