import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Board from './Board';
import CardDetails from './CardDetails';

import './scss/index.scss';

function BoardWrapper() {
    const location = useLocation();
    const isCardPath = location.pathname.startsWith('/c/');

    const { board, isLoading } = useSelector((state) => state.board);

    return (
        <div
            id="zoobbe-board"
            style={{
                backgroundImage: isLoading || !board?.cover?.url ? 'none' : `url(${board.cover.url})`,
            }}
        >
            <Board />
            {isCardPath && <CardDetails />}
        </div>
    );
}

export default BoardWrapper;
