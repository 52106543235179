import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterQuery } from '../../redux/Slices/filterQuerySlice';
import './scss/Filter.scss';
import Checkbox from '../Global/Checkbox';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';
import { areFiltersEqual, find, getFilters, initialFiltersState } from '../../utils/helpers';
import useOutsideClick from '../../hooks/useOutsideClick';
import { config } from '../../config';
import { fetchBoardById } from '../../redux/Slices/boardSlice';

const Filter = ({ setBoard, setLoading }) => {
    const { user } = useSelector((state) => state.user);
    const filterQuery = useSelector((state) => state.filterQuery);
    const { workspaces } = useSelector((state) => state.workspaces);
    const boardId = useSelector(selectBoardId);
    const members = useSelector(state => state.member.boardMembers);

    const [filters, setFilters] = useState(filterQuery);
    const [searchTerm, setSearchTerm] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isShowFilteredMembers, setShowFilteredMembers] = useState(false);
    const [searchSelectedMembers, setSearchSelectedMembers] = useState([]);

    const assignRef = useRef();
    const selectMembersRef = useRef();
    const dispatch = useDispatch();

    const updateFilters = async (newFilters) => {
        setLoading(true);
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${config.API_URI}/api/users/filters`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
          'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ filters: newFilters, boardId })
            });

            if (!response.ok) {
                throw new Error('Failed to update filters');
            }
            const data = await response.json();
            setBoard(data.board || { actionLists: [] });
            setLoading(false);
        } catch (error) {
            console.error('Error updating filters:', error);
        }
    };


    const handleChange = (e) => {
        const { name, checked } = e.target;
        let newFilters = { ...filters, [name]: checked };
        newFilters.filteredStatus = !areFiltersEqual(initialFiltersState, newFilters);

        setFilters(newFilters);
        dispatch(setFilterQuery(newFilters));
        updateFilters(newFilters); // Update filters when `filters` state changes
    };

    const handleKeywordChange = (e) => {
        let newFilters = { ...filters, keyword: e.target.value };
        newFilters.filteredStatus = !areFiltersEqual(initialFiltersState, newFilters);

        setFilters(newFilters);
        dispatch(setFilterQuery(newFilters));
        updateFilters(newFilters); // Update filters when `filters` state changes

    };

    const handleSelectLabel = (label) => {
        const newSelectedLabels = filterQuery.selectedLabels.includes(label)
            ? filterQuery.selectedLabels.filter(selected => selected !== label)
            : [...filterQuery.selectedLabels, label];

        let newFilters = { ...filters, selectedLabels: newSelectedLabels };
        newFilters.filteredStatus = !areFiltersEqual(initialFiltersState, newFilters);

        setFilters(newFilters);
        dispatch(setFilterQuery(newFilters));
        updateFilters(newFilters); // Update filters when `filters` state changes

    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setFilters(prev => ({ ...prev, selectMembers: true }));
    };

    useOutsideClick(selectMembersRef, () => setShowFilteredMembers(false));

    const handleSelectMembersCheckbox = (checked) => {
        let newSelectedMembers;

        if (checked) {
            // Select all members except the current user
            newSelectedMembers = members.map(member => member._id).filter(memberId => memberId !== user?.user?._id);
        } else {
            // Unselect all members
            newSelectedMembers = [];
        }

        let newFilters = { ...filters, selectMembers: checked, selectedMembers: newSelectedMembers };
        newFilters.filteredStatus = !areFiltersEqual(initialFiltersState, newFilters);

        setFilters(newFilters);
        dispatch(setFilterQuery(newFilters));
        updateFilters(newFilters);

        // Update the local search selected members state if necessary
        setSearchSelectedMembers(newSelectedMembers);
    };



    const handleSelectUser = (member) => {
        const isAlreadySelected = filters.selectedMembers.includes(member._id);

        let newSelectedMembers;

        if (isAlreadySelected) {
            newSelectedMembers = filters.selectedMembers.filter(selected => selected !== member._id);
        } else {
            newSelectedMembers = [...filters.selectedMembers, member._id];
        }

        const isAnyMemberSelected = newSelectedMembers.length > 0;

        let newFilters = {
            ...filters,
            selectedMembers: newSelectedMembers,
            selectMembers: isAnyMemberSelected
        };

        newFilters.filteredStatus = !areFiltersEqual(initialFiltersState, newFilters);

        setFilters(newFilters);
        dispatch(setFilterQuery(newFilters));
        updateFilters(newFilters);
    };

    const handleAssignedToMeChange = (checked) => {
        const isAlreadySelected = filters.selectedMembers.includes(user?.user?._id);

        let newSelectedMembers;

        if (checked && !isAlreadySelected) {
            newSelectedMembers = [...filters.selectedMembers, user?.user?._id];
        } else if (!checked && isAlreadySelected) {
            newSelectedMembers = filters.selectedMembers.filter(memberId => memberId !== user?.user?._id);
        } else {
            newSelectedMembers = filters.selectedMembers;
        }

        let newFilters = {
            ...filters,
            assignedToMe: checked,
            selectedMembers: newSelectedMembers,
        };

        newFilters.filteredStatus = !areFiltersEqual(initialFiltersState, newFilters);


        setFilters(newFilters);
        dispatch(setFilterQuery(newFilters));
        updateFilters(newFilters);
    };

    const getPlaceholderText = () => {
        const visibleSelectedMembers = filterQuery.selectedMembers.filter(
            memberId => memberId !== user?.user?._id
        );

        if (visibleSelectedMembers.length === 0) {
            return 'Select an assign';
        }

        return `${visibleSelectedMembers.length} member${visibleSelectedMembers.length > 1 ? 's' : ''} selected`;
    };

    // The rest of your component code

    const filteredMembers = members.filter(member =>
        member.name && // Ensure member has an _id
        member._id !== user?.user?._id && // Exclude the current user
        (member.name.toLowerCase().includes(searchTerm.toLowerCase()) || // Match by name
            member.username.toLowerCase().includes(searchTerm.toLowerCase())) // Match by username
    );



    return (
        <div className="filter-container">
            <h3 className="filter-title">Filter</h3>
            <input
                label="Enter a keyword..."
                variant="outlined"
                size="small"
                value={filters.keyword}
                onChange={handleKeywordChange}
                className='keyword'
                placeholder='Enter a keyword'
            />
            <span className='search-description'>Search cards, members, labels, and more.</span>

            {/* Members Filter */}
            <div className="filter-section">
                <h4>Members</h4>
                <div className="filter-options">
                    <Checkbox
                        checked={filters.noMembers}
                        onChange={(checked) => handleChange({ target: { name: 'noMembers', checked } })}
                        id="no-members-checkbox"
                    />
                    <span className="material-symbols-outlined person">person</span>
                    <span>No members</span>
                </div>
                <div className="filter-options">
                    <Checkbox
                        checked={filters.assignedToMe}
                        onChange={handleAssignedToMeChange}
                        id="assigned-to-me-checkbox"
                    />
                    {user && (
                        user.user.profilePicture ? (
                            <img
                                src={user.user.profilePicture}
                                alt={user.user.username}
                                style={{ width: "22px", height: "22px", borderRadius: "50%" }}
                            />
                        ) : (
                            <ImagePlaceholder key={user?.user?._id} size={22} text={user.user.username} />
                        )
                    )}
                    <span>Cards assigned to me</span>
                </div>
                <div className="filter-options" ref={selectMembersRef}>
                    <Checkbox
                        checked={filters.selectMembers}
                        onChange={handleSelectMembersCheckbox}
                        id="select-all-members-checkbox"
                    />


                    <div className='select-members'>
                        <div className={`select-option-label${isFocused ? ' focus' : ''}`} ref={assignRef}>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder={getPlaceholderText()}
                                onFocus={() => {
                                    setIsFocused(true);
                                    setShowFilteredMembers(true);
                                }}
                                onBlur={() => setIsFocused(false)}
                            />
                            <span className="material-symbols-outlined">
                                keyboard_arrow_down
                            </span>
                        </div>

                        {isShowFilteredMembers && (
                            <div className='board-members'>
                                {filteredMembers.length > 0 && (
                                    <div className="sbm-search-results">
                                        {filteredMembers.map((member, index) => {

                                            console.log(member);
                                            return (
                                                <div className='label'
                                                    key={index}
                                                    onClick={() => handleSelectUser(member)} // Unified click handler for the entire label
                                                    style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable area
                                                >
                                                    <Checkbox
                                                        checked={filters.selectedMembers.includes(member._id)}
                                                        onChange={() => handleSelectUser(member)} // Keep this for accessibility, but it’s redundant now
                                                        id={`select-members-checkbox-${member._id}`}
                                                    />

                                                    <div className="mention-member">
                                                        {member.profilePicture ? (
                                                            <img src={member.profilePicture} style={{ width: '16px', height: '16px' }} alt={member.name} />
                                                        ) : (
                                                            <ImagePlaceholder key={member._id} size={16} text={member.username} />
                                                        )}
                                                        <div className="suggession-handler">{`${member.name}`}</div>
                                                    </div>
                                                </div>

                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Due Date Filter */}
            <div className="filter-section">
                <h4>Due date</h4>
                <div className="filter-options">
                    <Checkbox
                        checked={filters.noDates}
                        onChange={(checked) => handleChange({ target: { name: 'noDates', checked } })}
                        id="no-dates-checkbox"
                    />
                    <span className="material-symbols-outlined no-dates">event</span>
                    <span>No due date</span>
                </div>
                <div className="filter-options">
                    <Checkbox
                        checked={filters.overdue}
                        onChange={(checked) => handleChange({ target: { name: 'overdue', checked } })}
                        id="overdue-checkbox"
                    />
                    <span className="material-symbols-outlined overdue">event_busy</span>
                    <span>Overdue</span>
                </div>
                <div className="filter-options">
                    <Checkbox
                        checked={filters.dueNextDay}
                        onChange={(checked) => handleChange({ target: { name: 'dueNextDay', checked } })}
                        id="due-in-next-day-checkbox"
                    />
                    <span className="material-symbols-outlined next-day">event_upcoming</span>
                    <span>Due in the next day</span>
                </div>
                <div className="filter-options">
                    <Checkbox
                        checked={filters.dueInTheNextWeek}
                        onChange={(checked) => handleChange({ target: { name: 'dueInTheNextWeek', checked } })}
                        id="due-in-next-week-checkbox"
                    />
                    <span className="material-symbols-outlined next-week">event_note</span>
                    <span>Due in the next week</span>
                </div>
                <div className="filter-options">
                    <Checkbox
                        checked={filters.dueInTheNextMonth}
                        onChange={(checked) => handleChange({ target: { name: 'dueInTheNextMonth', checked } })}
                        id="due-in-next-month-checkbox"
                    />
                    <span className="material-symbols-outlined next-month">event_available</span>
                    <span>Due in the next month</span>
                </div>
            </div>

            {/* Labels Filter */}
            <div className="filter-section">
                <h4>Labels</h4>
                <div className="filter-options">
                    <Checkbox
                        checked={filters.noLabels}
                        onChange={(checked) => handleChange({ target: { name: 'noLabels', checked } })}
                        id="no-labels-checkbox"
                    />
                    <span className="material-symbols-outlined label">label</span>
                    <span>No labels</span>
                </div>
                <div className="filter-options">
                    {filters.labels && filters.labels.map((label, index) => (
                        <label key={index}>
                            <Checkbox
                                checked={filterQuery.selectedLabels.includes(label)}
                                onChange={() => handleSelectLabel(label)}
                                id={`select-labels-checkbox-${label}`}
                            />
                            <div className="label-box" style={{ backgroundColor: label.color }}>
                                {label.name}
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Filter;
