import React from 'react';

const VerificationError = () => (
  <div style={{ textAlign: 'center', marginTop: '50px', color: 'var(--primary-text-color)' }}>
    <h2>Verification Failed</h2>
    <p>It looks like your verification link is invalid or has expired. Please try again.</p>
  </div>
);

export default VerificationError;
