import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { find, toSlug } from "../../utils/helpers";

import './scss/popoverBoardList.scss'
import ImagePlaceholder from "../Global/ImagePlaceholder";
import { togglePopover } from "../../redux/Slices/popoverSlice";

const PopoverBoardList = ({ member }) => {

    const { workspaces } = useSelector(state => state.workspaces);

    const boards = find.get(workspaces, { name: 'boardsByMember', memberId: member._id });

    const dispatch = useDispatch();

    return (
        <>
            <div className="board-list">
                <h2>Workspace boards</h2>
                <p>@{member.username} is a member of the following Workspace boards:</p>
                <ul>
                    {boards.map((board, index) => (

                        <li key={index} className="board-item">
                            <Link to={board.permalink} onClick={() => { dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null })) }}>
                                <div className="board-thumbnail">
                                    <ImagePlaceholder size={30} text={board.title} />
                                </div>
                                <div className="board-title">{board.title}</div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
}

export default PopoverBoardList;