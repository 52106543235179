import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uploadBackground } from '../../../redux/Slices/attachmentSlice';
import { hideSnackBar, showSnackBar } from '../../../redux/Slices/snackbarSlice';
import { fetchBoardById } from '../../../redux/Slices/boardSlice';
import Spinner from '../../Global/Spinner';
import { config } from '../../../config';


const Custom = () => {
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [isUrlValid, setIsUrlValid] = useState(true);
    const [isTextareaFocused, setIsTextareaFocused] = useState(false);
    const [backgroundImages, setBackgroundImages] = useState([]);
    const [isLoadingBackgrounds, setIsLoadingBackgrounds] = useState(false);

    const { status } = useSelector((state) => state.attachments);
    const { board } = useSelector((state) => state.board);

    const urlRef = useRef(null);
    const fileInputRef = useRef(null);

    const boardId = board.shortId;

    const dispatch = useDispatch();

    // Fetch background images from the backend
    const fetchBackgrounds = async () => {
        if (!boardId) return;

        setIsLoadingBackgrounds(true);
        try {

            const token = localStorage.getItem('accessToken');

            const response = await fetch(`${config.API_URI}/api/boards/${boardId}/backgrounds`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch background images');
            }

            const data = await response.json();

            console.log(data.backgrounds);
            setBackgroundImages(data.backgrounds);
        } catch (error) {
            console.error('Error fetching background images:', error);
            dispatch(showSnackBar({
                message: 'Failed to load background images',
                type: 'error',
            }));
        } finally {
            setIsLoadingBackgrounds(false);
        }
    };


    useEffect(() => {
        fetchBackgrounds();
    }, [boardId, dispatch]);

    useEffect(() => {
        if (urlRef.current) {
            urlRef.current.style.height = '22px';
            urlRef.current.style.height = `${urlRef.current.scrollHeight}px`;
        }
    }, [attachmentUrl]);

    useEffect(() => {
        if (status === 'uploadSucceeded') {
            dispatch(hideSnackBar());
            fetchBackgrounds(); // Reload background images after successful upload
        } else if (status === 'uploadFailed') {
            dispatch(showSnackBar({
                message: 'File upload failed',
                type: 'error',
            }));
        }
    }, [status, dispatch]);

    const validateImageUrl = (url) => {
        const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
        return imageExtensions.test(url);
    };

    const handleUrlChange = (e) => {
        const url = e.target.value;
        setAttachmentUrl(url);
        setIsUrlValid(validateImageUrl(url));
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        dispatch(showSnackBar({ message: 'Uploading files...', type: 'uploading' }));

        const response = await dispatch(uploadBackground({ file, id: boardId, apiPath: 'boards' }));

        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(fetchBoardById(boardId));
            dispatch(hideSnackBar());
        } else {
            dispatch(showSnackBar({ message: 'File upload failed', type: 'error' }));
        }
    };


    const handleBackgroundImageSelect = async (img) => {
        dispatch(showSnackBar({ message: 'Setting background...', type: 'uploading' }));


        try {
            const token = localStorage.getItem('accessToken');

            if (!token) {
                throw new Error('User is not authenticated. Access token is missing.');
            }

            const response = await fetch(`${config.API_URI}/api/boards/${boardId}/background`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ img }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to set background: ${response.status} ${errorText}`);
            }

            // Refresh the board data after successful upload
            dispatch(fetchBoardById(boardId));
            dispatch(hideSnackBar());
        } catch (error) {
            console.error('Error setting background:', error);
            dispatch(showSnackBar({ message: 'Failed to set background', type: 'error' }));
        }
    };

    return (
        <div className="add-attachment">
            {/* URL Upload Section */}
            {/* <div className="group">
                <h3>Attach URL</h3>
                <div className={`textarea-wrapper ${isTextareaFocused ? 'focused' : ''}`}>
                    <textarea
                        ref={urlRef}
                        type="url"
                        placeholder="Enter attachment URL"
                        value={attachmentUrl}
                        onChange={handleUrlChange}
                        rows={1}
                        style={{ overflow: 'hidden' }}
                        spellCheck={false}
                        onFocus={() => setIsTextareaFocused(true)}
                        onBlur={() => setIsTextareaFocused(false)}
                    />
                </div>
                {!isUrlValid && <p className="error-message">Please enter a valid URL.</p>}
                <button disabled={!isUrlValid}>Submit</button>
            </div> */}

            {/* File Upload Section */}
            <div className="group choose-backgrounds">
                <h3>Upload Background from your computer</h3>
                <button onClick={() => fileInputRef.current.click()} className="attach-file-btn">
                    {status === 'loading' ? 'Uploading...' :
                        status === 'uploadSucceeded' ? 'Upload Successful' :
                            'Choose a file'
                    }
                </button>
                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                />
            </div>

            <div className="group">
                <h3 className="background-group-title">or Choose an image from available backgrounds</h3>

                {isLoadingBackgrounds ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}>
                        <Spinner size={30} color="#3498db" speed={1.5} />
                    </div>
                ) : (

                    <div className="unsplash-background-images custom">

                        <div className="unsplash-images">
                            {backgroundImages.map((img, index) => {
                                const isLastImage = index === backgroundImages.length - 1;

                                return (
                                    <div
                                        className="board-background-select"
                                        key={img.id}
                                    // ref={isLastImage ? lastImageRef : null}
                                    >
                                        <span
                                            className="background-box"
                                            data-testid={`board-background-select-photo-${index}`}
                                            style={{ backgroundImage: `url(${img.signedUrl})` }}
                                            onClick={() => handleBackgroundImageSelect(img)}
                                        >
                                            <div className="large">
                                                <a
                                                    href={img.signedUrl}
                                                    target="_blank"
                                                    title={img.name}
                                                >
                                                    {img.name}
                                                </a>
                                            </div>
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>


                )}

            </div>

        </div>
    );
};

export default Custom;
